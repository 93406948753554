import { Grid, Toolbar, Divider, Button, InputBase, Switch } from '@mui/material';
import React from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import ListUserPedidosContainer from '../../../components/ListUserPedidosContainer';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const UserDetails = () => {
    const navigate = useNavigate();
    const sales = [
        { id: 1, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022',  quantity: 2, totalPrice: '120,00' },
        { id: 2, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '120,00' },
        { id: 3, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '120,00' },
        { id: 4, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '150,00' },
        { id: 5, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '45,00' },
        { id: 6, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '394,00' },
        { id: 7, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '45,00' },
        { id: 8, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '45,00' },
        { id: 9, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '45,00' },
        { id: 10, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '150,00' },
        { id: 11, numberSale: '1234', name: 'Lucas Bras', date: '22/07/2022', quantity: 2, totalPrice: '394,00' }
    ];

    return (
        <div>
            <Menu />
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', width: '97%' }}>
                <Grid container direction='row' style={{ paddingLeft: '17%' }}>
                    <PeopleAltOutlinedIcon sx={{ color: Theme.dark.primary, paddingRight: 1 }} />
                    <Text size={24} weight='600'>Usuários</Text>
                </Grid>
                <Grid container direction='row' alignItems='center' sx={{ width: '15%' }}>
                    <Text size={14} weight='600'>Ativo</Text>
                    <Switch defaultChecked />
                    <Button sx={{ width: '133px', height: '31px', padding: '8px' }} variant='contained'>
                        <SaveOutlinedIcon sx={{ color: 'white', paddingRight: 1 }} />
                        <Text size={12} weight='700'>SALVAR</Text>
                    </Button>
                </Grid>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%', marginBottom: '135px' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='flex-start' alignItems='flex-start' direction='column' sx={{ width: '100%' }}>
                        <Text size={18} weight='600'>Nome completo</Text>
                        <div style={{ width: '70%', height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
                            <InputBase
                                sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                placeholder="Seu nome"
                                inputProps={{ 'aria-label': 'Seu nome' }}
                                value='Lucas Brás'
                            />
                        </div>
                    </Grid>
                    <Grid container justifyContent='flex-start' alignItems='flex-start' direction='row' sx={{ width: '100%', marginTop: 4 }}>
                        <Grid container sx={{ width: '30%' }}>
                            <Text size={18} weight='600'>Telefone/Whatsapp</Text>
                            <div style={{ width: '70%', height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                    placeholder="(11) 99999-9999"
                                    inputProps={{ 'aria-label': '(11) 99999-9999' }}
                                    value='(11) 99999-9999'
                                />
                            </div>
                        </Grid>
                        <Grid container direction='column' sx={{ width: '60%', marginLeft: -8 }}>
                            <Text size={18} weight='600'>E-mail</Text>
                            <div style={{ width: '76%', height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                    placeholder="nome@exemplo.com"
                                    inputProps={{ 'aria-label': 'nome@exemplo.com' }}
                                    value='lucas.bras@bittareducacao.com.br'
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='flex-start' alignItems='flex-start' direction='row' sx={{ width: '100%', marginTop: 4 }}>
                        <Grid container direction='column' sx={{ width: '40%' }}>
                            <Text size={18} weight='600'>Unidade</Text>
                            <div style={{ width: '70%', height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                    placeholder="Selecione"
                                    inputProps={{ 'aria-label': 'Selecione' }}
                                    value='Instituto João Bittar'
                                />
                            </div>
                        </Grid>
                        <Grid container direction='column' sx={{ width: '40%', marginLeft: -1 }}>
                            <Text size={18} weight='600'>Função</Text>
                            <div style={{ width: '76%', height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                    placeholder="Ex: Designer"
                                    inputProps={{ 'aria-label': 'Ex: Designer' }}
                                    value='Designer UX/UI'
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ width: '58%', paddingLeft: '18%' }} >
                <Text size={18} weight='600'>Histórico de pedidos</Text>
                <ListUserPedidosContainer sales={sales} />
            </Grid>
        </div>
    )
}

export default UserDetails;