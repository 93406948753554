import { Button } from '@mui/material';
import React from 'react';
import Text from '../Text';
import './styles.css';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Theme from '../../style/Theme';
import { Container } from './styles';
import EmptyImage from '../../assets/images/empty-image.png';
import { formatNumber } from '../../utils/formatMoney';

const ProductCard = (props) => {
    const { name, price, addToCart, onClick, image, key } = props;
    return(
        <div className='parent' key={key}>
            <Container image={image !== null ? image : EmptyImage} onClick={onClick}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 25, width: '100%', alignItems: 'center' }}>
                    <div style={{ paddingLeft: 25 }}>
                        <Text size={10} weight='600' style={{ marginBottom: 3 }}>{name}</Text>
                        <Text size={16} weight='700'>R$ {formatNumber(price)}</Text>
                    </div>
                    <div style={{ paddingRight: 25 }}>
                        <Button onClick={addToCart} disableElevation={true} size='small' variant='contained' style={{ minWidth: 38, minHeight: 38 }} >
                            <ShoppingCartOutlinedIcon style={{ color: Theme.dark.primaryText, width: 20, height: 20 }} />
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ProductCard;
