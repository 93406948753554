import { Container, Grid, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { toast } from 'react-toastify';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { postCheckout, putCredits } from '../../../api/Checkout';

const ConfirmPayment = () => {
    const navigate = useNavigate();
    const cart = useStoreState(({ cart }) => cart.cart);
    const totalCountCart = useStoreState(({ cart }) => cart.totalCart);
    const user = useStoreState(({ user }) => user.user);

    const userStoreActions = useStoreActions(({ user }) => user.setUser);
    const updateItems = useStoreActions(({ cart }) => cart.updateTotalItems);

    const address = `${user.place_address}, ${user.place_number} - ${user.place_district}, ${user.place_city}`;
    // console.log(user)

    const [loading, setLoading] = useState(false);
    const [desabilitado, setDesabilitado] = useState(false);

    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };

    const handleUpdateCredits = async () => {
        try {
            const updateCredits = await putCredits({
                id_user: user.id,
                credits: user.credits
            })
            if(updateCredits.status === 200) {
                toast('Pedido realizado com sucesso!', { type: 'success' })
                updateItems({
                    totalItems: 0,
                    products: [],
                    totalValue: 0
                })
                navigate('/inicio')
            } else if (updateCredits.status === 403){
                logout();
            }
        } catch (error) {
            toast(error, { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const handleCheckout = async () => {
        setLoading(true)
        setDesabilitado(true)
        try {
            const body = {
                user_name: user.name,
                user_id: user.id,
                place: user.place_name,
                place_adress: address,
                product: cart,
                total: totalCountCart
            };

            if(user.credits >= totalCountCart) {
                const response = await postCheckout(body);
                if(response.status === 200) {
                    user.credits = user.credits - totalCountCart;
                    userStoreActions(user)
                    handleUpdateCredits()
                } else {
                    toast('OPS! Ocorreu um erro inesperado, tente novamente.', { type: 'error' })
                }
            } else {
                toast('OPS! Seus créditos são insuficientes.', { type: 'error' })
            }
        } catch (error) {
            toast(error, { type: 'error' })
        } finally {
            setLoading(false)
            setDesabilitado(false)
        }
    }

    return (
        <Container>
            <Grid marginTop='100px'>
                <Text size={24} weight='bold'>Confirmar pagamento</Text>
            </Grid>
            <Grid container marginTop='20px' justifyContent='space-between' marginBottom='20px' direction='row'>
                <Grid xs={12} sm={5.8} padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Text size={18} weight='500'>DETALHES DA COMPRA</Text>
                    {
                        cart.map((item) =>
                            <Grid container marginTop='8px' direction='row' justifyContent='space-between'>
                                <Text size={14} color={Theme.dark.grey}>x{item.quantity} {item.desc_prod[0].toUpperCase() + item.desc_prod.slice(1).toUpperCase()}</Text>
                                <Text size={14} color={Theme.dark.grey}>TAMANHO - {item.size_selected}</Text>
                            </Grid>
                        )
                    }
                </Grid>
                <Grid xs={12} sm={5.8} padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Text size={18} weight='500'>ENDEREÇO DA ENTREGA</Text>
                    <Grid container marginTop='8px' direction='row' justifyContent='space-between'>
                        <Text size={14} color={Theme.dark.grey}>{user.place_name[0].toUpperCase() + user.place_name.slice(1).toUpperCase()}</Text>
                    </Grid>
                    <Grid container marginTop='8px' direction='row' justifyContent='space-between'>
                        <Text size={14} color={Theme.dark.grey}>{address.toUpperCase()}</Text>
                    </Grid>
                    {/* <Grid container marginTop='8px' direction='row' justifyContent='space-between'>
                        <Text size={14} color={Theme.dark.grey}>uberlandia@bittareducacao.com.br</Text>
                    </Grid> */}
                    <Grid container marginTop='8px' direction='row' justifyContent='space-between'>
                        <Text size={14} color={Theme.dark.grey}>{user.place_phone.split('55-')[1]}</Text>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='center' alignItems='center' style={{ marginBottom: 180 }}>
                <Button onClick={() => navigate('/carrinho')} variant="outlined" style={{ width: 241, height: 53, marginRight: 25 }}>
                    <Text size={12} weight='700' color={Theme.dark.primary} style={{ padding: '32px 16px' }}>ALTERAR PEDIDO</Text>
                </Button>
                <Button onClick={handleCheckout} variant="contained" style={{ width: 241, height: 53 }} disabled={desabilitado}>
                    {loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> :<Text size={12} weight='700' color={Theme.dark.primaryText} style={{ padding: '32px 16px' }}>CONFIRMAR PAGAMENTO</Text>}
                </Button>
            </Grid>
            {/* <Text size={24} weight='600'>Destaques</Text>
            <Grid container direction='row' marginTop={3} marginBottom={7}>
                {
                    category1.map((item, index) => <ProductCard key={index} name={item.productName} price={item.price} image={item.image} addToCart={() => console.log('addtocart')} onClick={() => console.log('onclick')} />)
                }
            </Grid> */}
            <Grid container alignItems='center' justifyContent='center' marginBottom='32px' marginTop='-50px'>
                <Button endIcon={<ArrowForwardIcon />} onClick={() => navigate('/produtos')}>
                    <Text size={14} weight='700' color={Theme.dark.primary}>VER LOJA COMPLETA</Text>
                </Button>
            </Grid>
        </Container>
    )
}

export default ConfirmPayment;
