import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Drawer from './components/Drawer';
import Footer from './components/Footer';
import Theme from './style/Theme';
import LoginScreen from './containers/Auth/Login';
import FirstAccessScreen from './containers/Auth/FirstAccess';
import HomeScreen from './containers/Ecommerce/Home';
import ListProductsScreen from './containers/Ecommerce/ListProducts';
import ProductDetailScreen from './containers/Ecommerce/ProductDetail';
import CartScreen from './containers/Ecommerce/Cart';
import ConfirmPaymentScreen from './containers/Ecommerce/ConfirmPayment';
import ProfileScreen from './containers/Ecommerce/Profile';
import DashboardScreen from './containers/Admin/Dashboard';
import UsersScreen from './containers/Admin/Users';
import SalesScreen from './containers/Admin/Sales';
import ProductsScreen from './containers/Admin/Products';
import SettingsScreen from './containers/Admin/Settings';
import UserDetailsScreen from './containers/Admin/UserDetail';
import SaleDetailScreen from './containers/Admin/SaleDetail';
import ResetPassword from "./containers/Auth/ResetPassword";
import DressPolicy from "./containers/Ecommerce/DressPolicy";
import LoginOrder from "./containers/Auth/Login-Order";
import PurchasesScreen from "./containers/Ecommerce/Purchases";
import ChangeScreen from "./containers/Ecommerce/Change";
const MainRoutes = () => {
  const urlNotShowFooter = [
    '/',
    '/reset-password',
    '/primeiro-acesso',
    '/admin/dash',
    '/admin/usuarios',
    '/admin/pedidos',
    '/admin/produtos',
    '/admin/configuracoes',
    '/admin/usuarios/',
    '/admin/pedidos/'
  ];
  // const getMe = useStoreActions(({ user }) => user.getMe);
  // const user = useStoreState(({ user }) => user.user);

  // const init = async () => {
  //   const token = localStorage.getItem('@AUTH_TOKEN');

  //   if (token && !user?.name) {
  //     getMe();
  //   }
  // };

  // useEffect(() => {
  //   init();
  // }, [0]);
    const location = useLocation();
    const url = location.pathname.replace(/[0-9]/g, '');
    console.log(url)

    return (
        <div style={{ flex: 1, minHeight: '100vh', width: '100%', backgroundColor: Theme.dark.background }}>
            <Drawer>
              <Routes>
                  <Route path="/login" element={<LoginScreen />} />
                  <Route path="/login-order" element={<LoginOrder />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/admin/dash" element={<DashboardScreen />} />
                  <Route path="/admin/usuarios" element={<UsersScreen />} />
                  <Route path="/admin/usuarios/:id" element={<UserDetailsScreen />} />
                  <Route path="/admin/pedidos" element={<SalesScreen />} />
                  <Route path="/admin/pedidos/:id" element={<SaleDetailScreen />} />
                  <Route path="/admin/produtos" element={<ProductsScreen />} />
                  <Route path="/admin/configuracoes" element={<SettingsScreen />} />
                  <Route path="/primeiro-acesso" element={<FirstAccessScreen />} />
                  <Route path="/inicio" element={<HomeScreen />} />
                  <Route path="/produto/:id" element={<ProductDetailScreen />} />
                  <Route path="/produtos" element={<ListProductsScreen />} />
                  <Route path="/carrinho" element={<CartScreen />} />
                  <Route path="/finalizar-compra" element={<ConfirmPaymentScreen />} />
                  <Route path="/perfil" element={<ProfileScreen />} />
                  <Route path="/dress-policy" element={<DressPolicy />} />
                  <Route path="/compras" element={<PurchasesScreen />} />
                  <Route path="/troca" element={<ChangeScreen />} />
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="*" element={<LoginScreen />} />
              </Routes>
              {
                urlNotShowFooter.includes(url) ? null : <Footer />
              }
            </Drawer>
        </div>
    );

};

export default MainRoutes;
