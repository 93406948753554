import React from 'react';
import './styles.css';
import { ContainerImage } from './styles';
import EmptyImage from '../../assets/images/empty-image.png';

const ProductCardPurchase = (props) => {
    const { image, key } = props;
    return(
        <div className='parentPurchase' key={key}>
            <ContainerImage image={image !== null ? image : EmptyImage} />
        </div>
    )
}

export default ProductCardPurchase;