import { action } from 'easy-peasy';


export default {
  cart: [],
  totalCart: 0,
  totalItems: 0,

  addToCart: action((state, payload) => {
    state.cart = payload.cart
    state.totalCart = payload.totalValue
    state.totalItems = payload.totalItems
  }),

  updateTotalItems: action((state, payload) => {
    state.totalItems = payload.totalItems
    state.cart = payload.products
    state.totalCart = payload.totalValue
  }),

  removeOfCart: action((state, payload) => {
    state.cart = state.products;
    state.totalCart = payload.totalValue
    state.totalItems = payload.totalItems
  }),
};
