import React from 'react';
import { Divider } from '@mui/material';
import Text from '../../components/Text';
import { useNavigate } from 'react-router-dom';
import Theme from '../../style/Theme';
import Logo from '../../assets/images/logo.png';

export default function Footer() {

  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flex: 1, width: '100%', backgroundColor: Theme.dark.tertiary, borderTop: `1px solid ${Theme.dark.border}`, flexDirection: 'column' }}>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around', backgroundColor: Theme.dark.tertiary, height: 252 }}>
            {/*<div style={{ marginRight: -200 }}>*/}
            {/*    <Text size={18} style={{ marginBottom: 10, fontWeight: '600' }}>Categorias</Text>*/}
            {/*    <Divider style={{ backgroundColor: Theme.dark.border }} />*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, marginTop: 10, cursor: 'pointer' }}>Camisa</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Camiseta</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Calça</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Meia</Text>*/}
            {/*</div>*/}
            {/*<div style={{ marginRight: -200 }}>*/}
            {/*    <Text size={18} style={{ marginBottom: 10, fontWeight: '600' }}>Institucional</Text>*/}
            {/*    <Divider style={{ backgroundColor: Theme.dark.border }} />*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, marginTop: 10, cursor: 'pointer' }}>Sobre nós</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Como funciona?</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Blog</Text>*/}
            {/*    <Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Atendimento</Text>*/}
            {/*</div>*/}
            <div>
                <Text size={18} style={{ marginBottom: 10, fontWeight: '600' }}>Privacidade</Text>
                <Divider style={{ backgroundColor: Theme.dark.border }} />
                <Text onClick={() => navigate('/dress-policy')} style={{ marginBottom: 5, marginTop: 10, cursor: 'pointer' }}>Política de vestimenta</Text>
                {/*<Text onClick={() => navigate('/inicio')} style={{ marginBottom: 5, marginTop: 10, cursor: 'pointer' }}>Política de privacidade</Text>*/}
                {/*<Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Política de troca</Text>*/}
                {/*<Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Termos de uso</Text>*/}
                {/*<Text onClick={() => console.log('oi')} style={{ marginBottom: 5, cursor: 'pointer' }}>Cookies</Text>*/}
            </div>
            <div>
                <img src={Logo} style={{ height: 100 }} />
            </div>
        </div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: Theme.dark.border, height: 82 }}>
            <Text bold style={{ color: Theme.dark.grey }}>
                Bittar Tecnologia - Todos os direitos reservados
            </Text>
        </div>
    </div>
  );
}
