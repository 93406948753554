import {Container, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router';
import {useStoreState} from 'easy-peasy';
import Text from "../../../components/Text";
import {getHome} from "../../../api/Home";
import ProductCard from "../../../components/ProductCard";
import ArrowBack from '../../../assets/images/arrow-back.png';

import { Link } from 'react-router-dom';
import {a} from "caniuse-lite/data/browserVersions";

const DressPolicy = () => {
    const navigate = useNavigate();
    const user = useStoreState(({ user }) => user.user);

    const [ category, setCategory ] = useState(null);
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };
    const getProducts = async () => {
        try {
            const response = await getHome();
            if(response.status === 200) {
                const list1 = response.data.data;
                let list2 = [];
                const divisor = 4;
                for (var i = 0; i < list1.length; i = i + divisor) {
                    list2.push(list1.slice(i, i + divisor));
                }
                setCategory(list2)
            } else if(response.status === 403) {
                logout();
            } else {
                setMessageError('Ops! Ocorreu um problema.')
                setError(true)
            }
        } catch (error) {
            console.log(error)
            setMessageError('Ops! Ocorreu um problema.')
            setError(true)
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getProducts()
    }, [])

    return (
        <Container>
            <Grid container alignItems='center' justifyContent='left' marginTop='72px' marginBottom='31px'>
                <Text size={24} style={{  fontWeight: '700', color:'#fff' }}>Política de vestimenta</Text>
            </Grid>

            <Text size={20} align='left' style={{textAlign: 'left', fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                Vestir-se adequadamente no ambiente de trabalho denota
                respeito, credibilidade e compromisso, além de ser uma ferramenta
                de marketing pessoal que contribui decisivamente
                para passar uma imagem de profissionalismo e de atenção
                com a carreira.

            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                A utilização de uniformes realça a ideia de uma organização
                estruturada e competente, na qual todos os membros estão
                alinhados, como um verdadeiro time. Além disso, é uma
                medida de segurança, uma vez que nos permite identificar
                integrantes de nossa equipe onde quer que estejamos, bem
                como detectar facilmente a presença de pessoas estranhas
                em nossas instalações.
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                Por isso, resolvemos mais uma vez inovar e desenvolver uma
                linha completa de uniformes, com as mais variadas opções,
                de modo a comunicar ao mundo nosso profissionalismo e,
                ao mesmo tempo, respeitar a individualidade de cada integrante
                de nossa equipe e as peculiaridades e necessidades
                do trabalho que cada um desenvolve.
            </Text>

            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                Assim, dispomos de opções de uniformes das mais formais
                às mais informais, das mais tradicionais às mais confortáveis,
                das mais elegantes às mais esportivas, cabendo a você escolher
                o estilo de sua preferência, para que se sinta confortável,
                satisfeito e representado.
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                Naturalmente, nem todos os modelos estão disponíveis a
                todos os colaboradores. Por questões de saúde e segurança
                do trabalho, conformidade e adequação às necessidades de
                cada atividade, os colaboradores de cada setor ou de cada
                departamento contarão com opções previamente selecionadas
                pela unidade administrativa responsável pelos uniformes.
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                Porém, com toda essa liberdade, é importante ter bom senso
                e responsabilidade nas suas escolhas. Por isso, criamos este
                Código de Vestimenta, que o auxiliará no uso adequado dos
                uniformes. Seguindo as regras e orientações deste documento,
                você se sentirá mais confortável e trará ao ambiente
                de trabalho um nível maior de profissionalismo, organização
                e cuidado com a instituição, sem que precise abdicar de sua
                individualidade.
            </Text>

            <Text size={24} align={'justify'} style={{ fontWeight: '500', color: '#fff', marginBottom: '5px' }}>
                Regras
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                1. O uso do uniforme é obrigatório no desempenho de qualquer
                atividade profissional, seja dentro ou fora de nossas
                instalações. A única exceção são os professores de
                Ensino Superior, colaboradores das unidades administrativas
                relacionadas a assuntos jurídicos, colaboradores
                que lidem diretamente com investidores, diretores funcionais,
                diretores de projeto e executivos-chefes, aos quais
                o uso do uniforme é facultativo.
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                2. Além dos uniformes, a organização disponibiliza aos
                seus colaboradores alguns acessórios especiais, como
                squeezes (garrafas d’água), malas, mochilas e diversos
                outros;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                3. Por razões de segurança, a utilização dos uniformes é
                obrigatória para os prestadores de serviços e terceirizados
                cujos contratos de trabalho excedam ou possam vir
                a exceder três meses de atividade ininterrupta dentro
                das instalações da organização. Nesses casos, aplicam-se
                aos terceirizados as mesmas regras de solicitação,
                utilização e demais normas previstas neste documento;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                4. O uniforme e os acessórios especiais são disponibilizados
                gratuitamente para todos os colaboradores, terceirizados e prestadores de serviço;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                5. Cada colaborador dispõe de uma verba periódica
                (“crédito”) para a aquisição de uniformes e acessórios especiais.
                Para obter os seus uniformes e acessórios especiais,
                o colaborador deve acessar o site
                bittareducacao.com.br/uniformes, selecionar as peças
                de sua preferência, escolher o tamanho e finalizar
                seu pedido. Após concluída a solicitação, os uniformes
                e os acessórios especiais serão enviados diretamente
                para a unidade onde está alocado o colaborador;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                6. O colaborador tem até 3 (três) dias úteis após a contratação
                para acessar o site e proceder com a solicitação
                de seus uniformes;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                7. Após a solicitação dos primeiros uniformes, peças adicionais
                poderão ser solicitadas em qualquer tempo,
                desde que o colaborador tenha crédito disponível. Novos
                créditos serão adicionados periodicamente à conta do
                colaborador, de acordo com a programação da organização.
                Sempre que um novo crédito for adicionado, o
                colaborador será avisado pelos canais de comunicação
                oficiais da organização;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                8. Os créditos disponíveis nas contas do colaborador não
                têm prazo para expiração, não precisam ser utilizados
                imediatamente nem tampouco utilizados de uma só
                vez. A requisição de novas peças pode ocorrer gradualmente,
                de acordo com a necessidade;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                9. É vedado ao colaborador descaracterizar o uniforme,
                retirar emblemas ou alterá-lo de qualquer forma, com
                exceção da realização de ajustes de tamanho (tais
                como ajustes de barras, comprimento, dentre outros);
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                10. Os ajustes de tamanho não são obrigatórios. Caso o
                colaborador opte por realizar tais ajustes, deverá arcar
                com as despesas;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                11. É proibido ao colaborador utilizar, junto ao uniforme,
                qualquer peça, adorno ou acessórios que não o integrem,
                tais como lenços, agasalhos, chapéus, bonés etc.
                As únicas exceções admitidas são os acessórios pessoais
                (tais como joias, bijuterias, óculos de grau, óculos escuros,
                relógios e cintos). Nossa linha de uniformes
                contempla peças adequadas para todos os climas
                (como agasalhos leves e pesados, camisetas arejadas,
                vestidos sem manga, dentre diversas outras opções),
                tornando desnecessárias peças adicionais;
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                12. Admite-se a utilização de qualquer tipo de calçado
                junto ao uniforme, como tênis, sapatos, sandálias, sapatilhas,
                dentre outros. A utilização de cintos também
                é admitida. Contudo, tanto os calçados como os cintos
                devem ser, obrigatoriamente, de cor preta;
                <br/>
                <br/>
                13. Excepcionalmente, e apenas após a prévia, expressa e
                formal autorização do gestor imediato, será permitida
                a não utilização dos uniformes durante o exercício das
                atividades profissionais. Essas exceções devem ocorrer
                única e exclusivamente quando as regras, a tradição ou
                a conveniência demandarem algum tipo de vestuário
                não disponível em nossa linha de uniformes (tais como
                terno e gravata, roupas femininas formais ou de gala
                para eventos sociais, audiências judiciais ou reuniões
                em órgãos públicos, por exemplo);
                <br/>
                <br/>
                14. O uso do uniforme é obrigatório inclusive em viagens,
                quando o colaborador estiver em horário de trabalho
                ou no cumprimento de obrigações profissionais;
                <br/>
                <br/>
                15. É proibido o uso do uniforme e dos acessórios especiais
                fora das instalações da organização, com exceção
                de situações em que o colaborador estiver cumprindo
                atividade profissional externa ou se deslocando de sua
                residência para o trabalho. A utilização do uniforme
                profissional ou dos acessórios especiais durante compromissos
                pessoais, reuniões e compromissos profissionais estranhos
                às atividades da organização ou em
                quaisquer outras situações é considerada falta grave;
                <br/>
                <br/>
                16. É vedado ao colaborador postar imagens em suas redes
                sociais pessoais fazendo uso do uniforme ou dos
                acessórios especiais da organização, exceto quando
                prévia, expressa e formalmente autorizado por seu gestor
                imediato;
                <br/>
                <br/>
                17. A utilização do uniforme ou dos acessórios especiais
                em contexto estranho ao exercício profissional das atividades
                diretamente relacionadas à organização – na
                intenção de supostamente representá-la ou de agir em
                seu nome – é falta grave, passível de consequências
                jurídicas;
                <br/>
                <br/>
                18. É proibido doar, emprestar, ceder, vender ou permitir
                que qualquer pessoa que não seja colaborador, prestador
                de serviços ou terceirizado da organização faça uso
                do uniforme ou dos acessórios especiais;
                <br/>
                <br/>
                19. Os uniformes ou acessórios especiais inservíveis não
                podem, sob qualquer alegação ou pretexto, ser doados
                ou descartados pelo colaborador. As peças inservíveis
                devem ser devolvidas à unidade administrativa responsável
                pelos uniformes, a fim de que sejam inutilizadas.
                Esta regra é uma medida de segurança, criada para
                evitar que pessoas estranhas à organização possam se
                fazer passar por membros de nossa equipe;
                <br/>
                <br/>
                20. O uniforme e os acessórios especiais são um patrimônio
                da organização, e devem ser devolvidos ao final do
                contrato de trabalho (no caso dos colaboradores) ou do
                contrato de prestação de serviços (no caso dos prestadores
                de serviços e terceirizados). A não devolução
                acarretará o desconto dos valores das peças nos acertos devidos;
                <br/>
                <br/>
                21. A utilização do uniforme e/ou dos acessórios especiais
                após o desligamento do colaborador, prestador de serviços
                ou terceirizado da organização pode ser considerada crime.
            </Text>

            <Text size={24} align={'justify'} style={{ fontWeight: '500', color: '#fff', marginBottom: '5px' }}>
                Dicas
            </Text>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                É muito importante que você se atente às dicas de solicitação
                de uniformes, de alinhamento e de adequação ao ambiente
                de trabalho listadas a seguir, de modo a evitar qualquer tipo
                de desconforto ou insatisfação:
            </Text>
            <Text size={22} align={'justify'} style={{ fontWeight: '500', color: '#4789EB', marginBottom: '-15px' }}>
                Ajustes
            </Text>
            <ul style={{color: '#fff'}}>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        As únicas alterações permitidas nos uniformes são os
                        ajustes relacionados a tamanho e caimento, como ajustes
                        de barras, de mangas, de cintura etc. Os ajustes não
                        são obrigatórios, e devem ser realizados de acordo com
                        seus próprios critérios de conforto e apresentação;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                        No ambiente de trabalho, não é adequado o uso de
                        roupas justas e/ou curtas. No caso de ajustes no uniforme,
                        evite deixá-lo demasiadamente curto ou apertado.
                        Recomendamos, para vestidos e saias, que o comprimento
                        fique na altura dos joelhos, ou mais longo.
                    </Text>
                </li>
            </ul>
            <Text size={22} align={'justify'} style={{ fontWeight: '500', color: '#4789EB', marginBottom: '-15px' }}>
                Combinações e melhores práticas de vestuário
            </Text>
            <ul style={{color: '#fff'}}>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Ao utilizar uniformes com estampas, priorize uma combinação
                        harmônica, seguindo as sugestões de combinação de peças indicadas no site;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Recomendamos que barras de calças estejam sempre
                        firmes e os botões das camisas sejam sempre reforçados;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        As camisas masculinas devem ter os botões sempre fechados, com exceção do último botão do colarinho;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        As camisas femininas devem ter os botões sempre fechados.
                        Assim como as camisas masculinas, para melhor caimento,
                        podem ter o último botão, próximo do colarinho, aberto;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Procure ser coerente na combinação de suas roupas e
                        calçados. Evite, por exemplo, combinar tênis esportivos
                        com camisas e calças sociais, ou sapatos sociais com
                        calças e camisetas esportivas;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Procure utilizar acessórios harmônicos. Um relógio
                        mais esportivo combina com uma camiseta, enquanto
                        uma camisa ou um vestido social demandam acessórios
                        mais formais, por exemplo. Além disso, tente combinar
                        as cores do uniforme com as de seus acessórios;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Evite acessórios demasiadamente chamativos ou ostensivos,
                        tais como pulseiras, colares e brincos excessivamente grandes ou espessos;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Chapéus e bonés são permitidos somente para as funções
                        que demandam sua utilização enquanto equipamentos de
                        proteção individual. Nesses casos, apenas os
                        equipamentos fornecidos pela organização são admitidos.
                        Caso você possua alguma condição para a qual o
                        uso de chapéu ou boné seja recomendado, ou mesmo
                        se precisar utilizar algum desses acessórios para sentir-se
                        mais confortável, solicite autorização prévia ao seu
                        gestor imediato;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Mantenha suas calças com um comprimento adequado à sua altura. Calças muito longas ou muito curtas
                        passam má impressão.
                    </Text>
                </li>
            </ul>

            <Text size={22} align={'justify'} style={{ fontWeight: '500', color: '#4789EB', marginBottom: '-15px' }}>
                Maquiagens, unhas e cabelos
            </Text>
            <ul style={{color: '#fff'}}>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Sugerimos que maquiagens sejam utilizadas de modo
                        comedido, priorizando tons neutros e evitando cores
                        exageradamente vibrantes;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Mantenha suas unhas e cabelos sempre limpos.
                        Lembre-se de que a boa higiene pessoal, além de uma
                        demonstração de respeito para com seus colegas de trabalho,
                        é também uma questão de saúde.
                    </Text>
                </li>
            </ul>


            <Text size={22} align={'justify'} style={{ fontWeight: '500', color: '#4789EB', marginBottom: '-15px' }}>
                Acessórios especiais
            </Text>
            <ul style={{color: '#fff'}}>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Nas mesas de trabalho, será permitido apenas o uso
                        das squeezes (garrafas d’água) disponibilizadas pela
                        organização;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Caso você viaje a trabalho com frequência, procure utilizar,
                        sempre que possível, malas e mochilas disponibilizadas
                        pela organização. Essa atitude é uma medida de
                        segurança que desestimula furtos e roubos e permite
                        que você identifique sua bagagem com muito mais facilidade,
                        evitando transtornos.
                    </Text>
                </li>
            </ul>


            <Text size={22} align={'justify'} style={{ fontWeight: '500', color: '#4789EB', marginBottom: '-15px' }}>
                Solicitação de uniformes
            </Text>
            <ul style={{color: '#fff'}}>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Evite solicitar muitas peças de uma única vez. Com menos
                        peças a cada pedido, você pode ser mais assertivo
                        nos tamanhos e escolher aos poucos as opções mais
                        adequadas ao seu gosto;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Siga sempre o manual de medidas presente no site.
                        Procure ter à mão suas medidas ao solicitar suas peças,
                        evitando assim errar nos tamanhos ou escolher peças
                        que demandarão muitos ajustes;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Planeje as combinações que você pode fazer com as
                        peças antes de solicitá-las. Fazer um mix de calças sociais
                        e camisetas esportivas, por exemplo, pode deixá-lo
                        com poucas opções de variações ou forçá-lo a aderir a
                        combinações inadequadas ou deselegantes. Se pedir
                        camisetas esportivas, opte por calças jeans, que combinam
                        melhor. Se optar por peças sociais, busque opções nessa
                        mesma linha. Procure manter a coerência em seu visual;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Se possível, utilize as combinações sugeridas pelo site,
                        tanto na solicitação como na utilização de seus uniformes.
                        Elas foram criadas por especialistas em moda;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '25px' }}>
                        Procure ter opções de peças que atendam às suas necessidades.
                        Tenha ao menos um agasalho, mesmo que
                        esteja em uma região mais quente. Se estiver em uma
                        região mais fria, evite pedir roupas muito frescas, e assim
                        por diante. Pense sempre no que mais se adequa
                        à sua realidade;
                    </Text>
                </li>
                <li>
                    <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '31px' }}>
                        Se alguma peça em especial lhe agradou, não hesite
                        em solicitar vários itens iguais e utilizar a mesma
                        combinação todos os dias. A variação das peças é uma opção,
                        não uma obrigação.
                    </Text>
                </li>
            </ul>
            <Text size={20} align='left' style={{ fontWeight: '400', color: '#fff', marginBottom: '201px' }}>
                Ainda que sua atividade não exija contato
                direto com o público externo, sua imagem
                continua sendo muito importante, pois você
                é um representante da organização. Por isso,
                cuide para que a sua imagem sempre reflita o
                melhor de você.
            </Text>

            <Text size={24} weight='600'>Destaques</Text>
            <Grid container direction='row' marginTop={3} marginBottom={7}>
                {
                    category && category[1].map((item) => <ProductCard id={item.cod_prod} key={item.cod_prod} name={item.desc_prod} price={parseInt(item.cost)} image={item.link_img01} addToCart={() => console.log('addtocart')} onClick={() => navigate(`/produto/${item.cod_prod}`, { state: {product: item} })} />)
                }
            </Grid>

            <Grid container alignItems='center' justifyContent='center' marginTop='32px' marginBottom='31px' >
                <Link to='/produtos'><Text size={17} style={{float:'left', fontWeight: '700', color:'#4789EB', marginRight:'10px' }}>VER LOJA COMPLETA</Text>
                    <img src={ArrowBack} />
                </Link>
            </Grid>
        </Container>

    )
}

export default DressPolicy;
