import { Grid, Toolbar, Divider, Button } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ListContainer from '../../../components/ListContainer';

const Dashboard = () => {
    const navigate = useNavigate();
    const sales = [
        { id: 1, numberSale: '1234', buyer: 'Lucas Bras', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 2, numberSale: '1234', buyer: 'Devon Lane', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 3, numberSale: '1234', buyer: 'Savannah Nguyen', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 4, numberSale: '1234', buyer: 'Annette Black', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENTREGUE' },
        { id: 5, numberSale: '1234', buyer: 'Guy Hawkins', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 6, numberSale: '1234', buyer: 'Bessie Cooper', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'CANCELADO' },
        { id: 7, numberSale: '1234', buyer: 'Jenny Wilson', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 8, numberSale: '1234', buyer: 'Eleanor Pena', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 9, numberSale: '1234', buyer: 'Arlene McCoy', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 10, numberSale: '1234', buyer: 'Jacob Jones', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENTREGUE' },
        { id: 11, numberSale: '1234', buyer: 'Courtney Henry', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'CANCELADO' }
    ];

    return (
        <div>
            <Menu />
            <Toolbar>
                <GridViewOutlinedIcon sx={{ color: Theme.dark.primary, paddingLeft: '17%', paddingRight: 1 }} />
                <Text size={24} weight='600'>Dashboard</Text>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Text size={18} weight='600'>Últimos pedidos</Text>
                        <Button>
                            <Text size={12} color={Theme.dark.primary} weight='600' style={{ textDecoration: 'underline' }}>ver todos os pedidos</Text>
                        </Button>
                    </Grid>
                    <ListContainer sales={sales} />
                </Grid>
                <Grid container direction='column' style={{ width: '30%' }}>
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Relatório de pedidos</Text>
                    </Grid>
                    <Grid container direction='row' justifyContent='flex-start' style={{ marginLeft: '10%', width: '80%' }}>
                        <Grid container sx={{ backgroundColor: Theme.dark.secondary, padding: '7px', width: '148px', height: '56px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
                            <Text size={12} weight='600'>Realizados</Text>
                            <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '8px', marginTop: '4px', width: '100%' }} />
                            <Text size={12} weight='600'>125</Text>
                        </Grid>
                        <Grid container sx={{ backgroundColor: Theme.dark.yellow10, padding: '7px', width: '148px', height: '56px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.yellow}` }}>
                            <Text size={12} weight='600'>Enviados</Text>
                            <Divider style={{ backgroundColor: Theme.dark.yellow, marginBottom: '8px', marginTop: '4px', width: '100%' }} />
                            <Text size={12} weight='600'>125</Text>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='flex-start' style={{ marginLeft: '10%', width: '80%' }}>
                        <Grid container sx={{ backgroundColor: Theme.green.tertiary10, padding: '7px', width: '148px', height: '56px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.green.tertiary}` }}>
                            <Text size={12} weight='600'>Entregues</Text>
                            <Divider style={{ backgroundColor: Theme.green.tertiary, marginBottom: '8px', marginTop: '4px', width: '100%' }} />
                            <Text size={12} weight='600'>125</Text>
                        </Grid>
                        <Grid container sx={{ backgroundColor: Theme.dark.danger10, padding: '7px', width: '148px', height: '56px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.danger}` }}>
                            <Text size={12} weight='600'>Cancelados</Text>
                            <Divider style={{ backgroundColor: Theme.dark.danger, marginBottom: '8px', marginTop: '4px', width: '100%' }} />
                            <Text size={12} weight='600'>125</Text>
                        </Grid>
                    </Grid>
                    <Divider style={{ width: '66%', backgroundColor: Theme.dark.border, marginLeft: '10%', marginTop: 15, marginBottom: 20 }} />
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Notificações</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
                        <Text size={10} weight='500'>O item <spam style={{ fontWeight: 'bold', color: Theme.dark.primary }}>Camiseta azul</spam>, está com o estoque baixo!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>3 Peças</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.danger}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#03215 Lucas Brás</spam>, está atrasado do prazo de entrega!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>2 Dias</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.yellow}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#1562 Arlene McCoy</spam>, precisa ser enviado para os correios!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard;