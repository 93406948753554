import { Toolbar, Grid, Button, Divider, TextField } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import ListContainer from '../../../components/ListContainer';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const Sales = () => {
    const navigate = useNavigate();
    const sales = [
        { id: 1, numberSale: '1234', buyer: 'Lucas Bras', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 2, numberSale: '1234', buyer: 'Devon Lane', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 3, numberSale: '1234', buyer: 'Savannah Nguyen', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'NOVO' },
        { id: 4, numberSale: '1234', buyer: 'Annette Black', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENTREGUE' },
        { id: 5, numberSale: '1234', buyer: 'Guy Hawkins', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 6, numberSale: '1234', buyer: 'Bessie Cooper', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'CANCELADO' },
        { id: 7, numberSale: '1234', buyer: 'Jenny Wilson', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 8, numberSale: '1234', buyer: 'Eleanor Pena', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 9, numberSale: '1234', buyer: 'Arlene McCoy', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENVIADO' },
        { id: 10, numberSale: '1234', buyer: 'Jacob Jones', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'ENTREGUE' },
        { id: 11, numberSale: '1234', buyer: 'Courtney Henry', city: 'Americana', date: '22/07/2022', quantity: 1, status: 'CANCELADO' }
    ];

    return (
        <div>
            <Menu />
            <Toolbar>
                <Grid container direction='row' alignItems='center' style={{ paddingLeft: '17%', width: '35%' }}>
                    <ContentPasteOutlinedIcon sx={{ color: Theme.dark.primary, paddingRight: 1 }} />
                    <Text size={24} weight='600'>Pedidos</Text>
                </Grid>
                <Grid container direction='row' alignItems='center' style={{ width: '65%' }}>
                    <Button>
                        <SearchOutlinedIcon />
                    </Button>
                    <TextField id="standard-basic" label="Buscar pedido" variant="standard" inputProps={{ style: { borderBottom: `1px solid ${Theme.dark.greySuperLight}` } }} InputLabelProps={{ style: { color: Theme.dark.greyLight } }} sx={{ width: '58%', marginBottom: 2 }} />
                </Grid>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='flex-start' alignItems='center'>
                        <Text size={18} weight='600'>Filtros</Text>
                        <Grid 
                            container 
                            alignItems='center' 
                            justifyContent='center' 
                            sx={{ 
                                width: '100px', 
                                height: '20px', 
                                borderRadius: 10, 
                                marginLeft: '24px',
                                border: `1.5px solid ${Theme.dark.primary}`,
                                cursor: 'pointer'
                            }}
                        >
                            <Text weight='700' size={10} color={Theme.dark.primary}>NOVO</Text>
                        </Grid>
                        <Grid 
                            container 
                            alignItems='center' 
                            justifyContent='center' 
                            sx={{ 
                                width: '100px', 
                                height: '20px', 
                                borderRadius: 10,
                                marginLeft: '24px',
                                border: `1.5px solid ${Theme.green.tertiary}`,
                                cursor: 'pointer'
                            }}
                        >
                            <Text weight='700' size={10} color={Theme.green.tertiary}>ENTREGUE</Text>
                        </Grid>
                        <Grid 
                            container 
                            alignItems='center' 
                            justifyContent='center' 
                            sx={{ 
                                width: '100px', 
                                height: '20px', 
                                borderRadius: 10,
                                marginLeft: '24px',
                                border: `1.5px solid ${Theme.dark.yellow}`,
                                cursor: 'pointer' 
                            }}
                        >
                            <Text weight='700' size={10} color={Theme.dark.yellow}>ENVIADO</Text>
                        </Grid>
                        <Grid 
                            container 
                            alignItems='center' 
                            justifyContent='center' 
                            sx={{ 
                                width: '100px', 
                                height: '20px', 
                                borderRadius: 10,
                                marginLeft: '24px',
                                border: `1.5px solid ${Theme.dark.danger}`,
                                cursor: 'pointer'
                            }}
                        >
                            <Text weight='700' size={10} color={Theme.dark.danger}>CANCELADO</Text>
                        </Grid>
                    </Grid>
                    <ListContainer sales={sales} />
                </Grid>
                <Grid container direction='column' style={{ width: '30%' }}>
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Notificações</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
                        <Text size={10} weight='500'>O item <spam style={{ fontWeight: 'bold', color: Theme.dark.primary }}>Camiseta azul</spam>, está com o estoque baixo!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>3 Peças</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.danger}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#03215 Lucas Brás</spam>, está atrasado do prazo de entrega!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>2 Dias</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.yellow}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#1562 Arlene McCoy</spam>, precisa ser enviado para os correios!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Sales;