import {Breadcrumbs, Button, CircularProgress, Container, Grid, Link, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import {useNavigate} from 'react-router';
import InputBase from '@mui/material/InputBase';
import {Search} from "@mui/icons-material";
import ProductCardPurchasing from "../../../components/ProductCardPurchasing";
import {listPurchases} from "../../../api/CheckoutProduct";

import moment from "moment";
import 'moment/locale/pt-br'

const Purchase = () => {

    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false);

    const listPurchase = async () => {
        setLoading(true)
        let userLocal = JSON.parse(localStorage.getItem('USER'))
        try {
            const response = await listPurchases(userLocal.id);
            await setPurchases(response.data);
        } catch (error) {
            console.log(error)
        }finally {
            setLoading(false)
        }
    }

    const getDateHistory = (arrayHistory, statusHistory) => {
        const array = arrayHistory.filter((history) => history.status == statusHistory)
        return array[0].created_at;
    }

    useEffect(() => {
        listPurchase()
    }, []);

    return (
        <Container>
            <Breadcrumbs style={{ fontWeight: '700', color: Theme.dark.border,marginTop:32, fontSize: 12 }} aria-label="breadcrumb">
                <Link underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' onClick={() => navigate('/home')}>
                    Home
                </Link>
                <Typography fontSize={12} fontWeight='700' color={Theme.dark.grey}> Compras </Typography>
            </Breadcrumbs>
            <Grid marginTop='25px'>
                <Text size={24} weight='bold'>Histórico de pedidos</Text>
            </Grid>
            <Grid container  spacing={1} marginTop='25px'>
                <Grid item xs={9} md={3} >
                    <div style={{ width: 264, height: '38px', display: 'flex', alignItems: 'center',  borderRadius: 40, background: `${Theme.dark.tertiary}`}}>
                        <InputBase
                            sx={{ ml: 1, flex: 10, color: Theme.dark.primaryText }}
                            placeholder="Search…"
                            style={{textOverflow: 'ellipsis !important',}}
                            inputProps={{ 'aria-label': 'Pr' }}
                            startAdornment={<Search/>}
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}


                        />
                    </div>
                </Grid>
                <Grid item xs={3} md={8} style={{marginTop:9}} >
                    {
                        purchases.length > 0 ?
                            <Text color={Theme.dark.grey}>{purchases.length} produtos</Text>
                            : <Text color={Theme.dark.grey}>0 produto</Text>
                    }
                </Grid>
            </Grid>
            <Grid>
                {
                    purchases.length > 0 ?
                        purchases
                            .filter(item => {
                                if (!searchValue) return true
                                if (item.desc_prod.toUpperCase().includes(searchValue.toUpperCase()) ) {
                                    return true
                                }
                            })
                            .map((item,index) =>
                                <>
                                    {(function() {
                                        if (item.status === "DONE_CHANGE") {
                                            return(
                                                <Grid marginTop='20px' justifyContent='space-between' marginBottom='20px' >
                                                    <Grid item  xs={12}  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                                                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                                                            <Text size={13} color={Theme.dark.primaryText} style={{ marginLeft:10 }}>{moment(item.created_at).format('LL')}</Text>
                                                        </Grid>
                                                        <Grid container >
                                                            <Grid item xs={4} md={1.3}>
                                                                <ProductCardPurchasing image={item.foto} />
                                                            </Grid>
                                                            <Grid item xs={6} md={8.7} style={{}}>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={12} color={Theme.dark.green}>Troca finalizada</Text>
                                                                </Grid>
                                                                <Grid style={{marginTop: 4}}>
                                                                    <Text size={16} color={Theme.dark.primaryText}>Troca solicitada dia&nbsp;
                                                                        {
                                                                            item.checkout_history.length > 0 ?
                                                                            moment(getDateHistory(item.checkout_history,'CHANGE')).format('LL') :
                                                                                moment(item.created_at).format('LL')
                                                                        }
                                                                    </Text>
                                                                    <ul style={{fontSize:10,color: 'white',listStyleImage: 'none', padding: 0, marginTop:-2, marginLeft:14}}>
                                                                        <li>
                                                                            <Text style={{marginTop: 5}} weight={400} size={12} color={Theme.dark.primaryText}>Produto devolvido.</Text>
                                                                        </li>
                                                                    </ul>
                                                                </Grid>
                                                                <Grid style={{marginTop: 18}}>
                                                                    <Text size={16} color={Theme.dark.primaryText}>{item.desc_prod}</Text>
                                                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{item.quantity} unid | Tam: {item.size_selected} </Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4} md={2} style={{flex: 1, paddingTop:16}} container
                                                                  direction="row"
                                                                  justifyContent="flex-start"
                                                                  alignItems="flex-start">
                                                                <Grid>
                                                                    <Text>Código</Text>
                                                                    <Text color={Theme.dark.primary}>
                                                                        {item.nr_change}
                                                                    </Text>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (item.status === "CHANGE") {
                                            return(
                                                <Grid marginTop='20px' justifyContent='space-between' marginBottom='20px' >
                                                    <Grid item  xs={12}  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                                                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                                                            <Text size={13} color={Theme.dark.primaryText} style={{ marginLeft:10 }}>{moment(item.created_at).format('LL')}</Text>
                                                        </Grid>
                                                        <Grid container >
                                                            <Grid item xs={4} md={1.3}>
                                                                <ProductCardPurchasing image={item.foto} />
                                                            </Grid>
                                                            <Grid item xs={6} md={8.7} style={{}}>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={12} color={Theme.dark.yellow}>Processando Troca</Text>
                                                                </Grid>
                                                                <Grid style={{marginTop: 4}}>
                                                                    <Text size={16} color={Theme.dark.primaryText}>Troca solicitada dia&nbsp;
                                                                        {
                                                                            item.checkout_history.length > 0 ?
                                                                                moment(getDateHistory(item.checkout_history,'CHANGE')).format('LL') :
                                                                                moment(item.created_at).format('LL')
                                                                        }
                                                                    </Text>
                                                                    <ul style={{fontSize:10,color: 'white',listStyleImage: 'none', padding: 0, marginTop:-2, marginLeft:14}}>
                                                                        <li>
                                                                            <Text style={{marginTop: 5}} weight={400} size={12} color={Theme.dark.primaryText}>Aguardando envio do produto</Text>
                                                                        </li>
                                                                    </ul>
                                                                </Grid>
                                                                <Grid style={{marginTop: 18}}>
                                                                    <Text size={16} color={Theme.dark.primaryText}>{item.desc_prod}</Text>
                                                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{item.quantity} unid | Tam: {item.size_selected} </Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4} md={2} style={{flex: 1, paddingTop:16}} container
                                                                  direction="row"
                                                                  justifyContent="flex-start"
                                                                  alignItems="flex-start">
                                                                <Grid>
                                                                    <Text>Código</Text>
                                                                    <Text color={Theme.dark.primary}>
                                                                        {item.nr_change}
                                                                    </Text>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (item.status === "OPEN"||item.status === "OPEN_NO_STOCK") {
                                            return (
                                                <Grid marginTop='20px' justifyContent='space-between' marginBottom='20px' >
                                                    <Grid item xs={12}  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                                                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                                                            <Text size={13} color={Theme.dark.primaryText} style={{ marginLeft:10 }}>{moment(item.created_at).format('LL')}</Text>
                                                        </Grid>
                                                        <Grid container >
                                                            <Grid item xs={4} md={1.3}>
                                                                <ProductCardPurchasing image={item.foto} />
                                                            </Grid>
                                                            <Grid item xs={6} md={8.7} style={{}}>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={16} color={Theme.dark.yellow}>Solicitado</Text>
                                                                </Grid>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={20} color={Theme.dark.primaryText}>{item.desc_prod}</Text>
                                                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{item.quantity} unid | Tam: {item.size_selected} </Text>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (item.status === "RETURNED") {
                                            return (
                                                <Grid marginTop='20px' justifyContent='space-between' marginBottom='20px' >
                                                    <Grid item xs={12}  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                                                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                                                            <Text size={13} color={Theme.dark.primaryText} style={{ marginLeft:10 }}>{moment(item.created_at).format('LL')}</Text>
                                                        </Grid>
                                                        <Grid container >
                                                            <Grid item xs={4} md={1.3}>
                                                                <ProductCardPurchasing image={item.foto} />
                                                            </Grid>
                                                            <Grid item xs={6} md={8.7} style={{}}>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={16} color={Theme.dark.greenSecondary}>Devolvido</Text>
                                                                </Grid>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={20} color={Theme.dark.primaryText}>{item.desc_prod}</Text>
                                                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{item.quantity} unid | Tam: {item.size_selected} </Text>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid marginTop='20px' justifyContent='space-between' marginBottom='20px' >
                                                    <Grid item xs={12}  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                                                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                                                            <Text size={13} color={Theme.dark.primaryText} style={{ marginLeft:10 }}>{moment(item.created_at).format('LL')}</Text>
                                                        </Grid>
                                                        <Grid container >
                                                            <Grid item xs={4} md={1.3}>
                                                                <ProductCardPurchasing image={item.foto} />
                                                            </Grid>
                                                            <Grid item xs={6} md={8.7} style={{}}>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={16} color={Theme.dark.green}>Entregue</Text>
                                                                </Grid>
                                                                <Grid style={{marginTop: 14}}>
                                                                    <Text size={20} color={Theme.dark.primaryText}>{item.desc_prod}</Text>
                                                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{item.quantity} unid | Tam: {item.size_selected} </Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4} md={2.0} style={{flex: 1, paddingTop:16}} container
                                                                  direction="column"
                                                                  justifyContent="flex"
                                                                  alignItems="flex-end">
                                                                <Button onClick={() => navigate(`/produto/${item.cod_prod}`)} variant="outlined" style={{ width: 182, height: 32 }}>
                                                                    <Text size={12} weight='500' color={Theme.dark.primary}>Comprar novamente</Text>
                                                                </Button>
                                                                <Link onClick={() => navigate(`/troca`, { state: { purchase: item } })} underline="none">
                                                                    <Text size={12}
                                                                          style={{
                                                                              textDecoration: 'none',
                                                                              cursor: 'pointer',
                                                                              fontWeight: '700',
                                                                              color: Theme.dark.primary,
                                                                              marginTop: 72
                                                                          }}>Troca</Text>
                                                                </Link>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })()}
                                </>
                            )
                        :
                        <>
                            {(function (){
                                if(loading){
                                    return (
                                        <Grid container alignItems='center' justifyContent='center' direction='row' marginTop={3} marginBottom={7}>
                                            <CircularProgress size={50} style={{ color: '#fff', marginBottom: 20 }} />
                                        </Grid>
                                    );
                                }else{
                                    return (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Text size={20} color={Theme.dark.grey} style={{margin: 60}}>Vazio</Text>
                                        </Grid>
                                    )
                                }
                            })()}
                        </>

                }
            </Grid>
        </Container>
    )
}

export default Purchase;
