import { Container, Grid, Breadcrumbs, Link, Typography, Divider, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProductCard from '../../../components/ProductCard';
import Text from '../../../components/Text';
import BgLogin from '../../../assets/images/background-login.png';
import ImgHeader from '../../../assets/images/produtos.png';
import {BootstrapInput,SelectOptions, HeaderImgContainer, ImgContainer} from './styles';
import Theme from '../../../style/Theme';
import { useSearchParams } from 'react-router-dom';
import { listAllProducts } from '../../../api/Product';
import { useNavigate } from 'react-router';
import { useStoreState } from 'easy-peasy';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const ListProducts = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryFilter = searchParams.get('busca') ? searchParams.get('busca') : null;
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [offset, setOffset] = useState(1);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sexoFilter, setSexoFilter] = useState('');
    const [messageError, setMessageError] = useState('');

    const cart = useStoreState(({ cart }) => cart);

    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };
    const listProducts = async () => {
        setLoading(true)
        try {
            const response = await listAllProducts(offset,queryFilter);
            if(response.status === 200) {
                setProducts( response.data.data)
                setQuantity(response.data.count)
                setOffset(offset+1)
            } else if (response.status === 403) {
                logout();
            } else {
                setError(true)
                setMessageError('Products not found')
            }
        } catch (error) {
            console.log(error)
            setError(true)
            setMessageError('Products not found')
        } finally {
            setLoading(false)
        }
    }

    const total = products && products.reduce(getTotal, 0);
    
    function getTotal(total, item) {
        return total + (item.value * item.quantity);
    }

    const handleChange = (event) => {
        setSexoFilter(event.target.value);
    };



    useEffect(() => {
        listProducts()
    }, [searchParams]);

    return (
        <div>
            <HeaderImgContainer image={ImgHeader} />
            <Container>
                <Breadcrumbs style={{ fontWeight: '700', color: Theme.dark.border, marginTop: 24, fontSize: 12 }} aria-label="breadcrumb">
                    <Link style={{ cursor: 'pointer' }} underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' onClick={() => navigate('/inicio')}>
                        Home
                    </Link>
                    <Typography fontSize={12} fontWeight='700' color={Theme.dark.grey}>Produtos <spam style={{ color: Theme.dark.border, marginLeft: 6, fontWeight: '700' }}>/</spam></Typography>
                </Breadcrumbs>
                {
                    queryFilter !== null &&
                        <Grid container justifyContent='center' alignItems='center'>
                            <Text size={24}>Resultados da busca por: "{queryFilter}"</Text>
                        </Grid>
                }
                <Divider flexItem style={{ backgroundColor: Theme.dark.border, marginTop: 23 }} />
                <Grid container style={{ height: 60 }} direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid container xs={6} >
                        <div>
                            <SelectOptions name="cars" id="cars"  onChange={handleChange} value={sexoFilter}>
                                <option value={'Todos'} none selected><div style={{padding: 20}}>Gênero</div></option>
                                <option value={'Masculino'}>Masculino</option>
                                <option value={'Feminino'}>Feminino</option>
                            </SelectOptions>
                        </div>
                        <Divider flexItem orientation='vertical' style={{ backgroundColor: Theme.dark.border, width: 1, height: 30, marginTop: 9, marginLeft: 15 }} />
                    </Grid>
                    <Text weight='700' style={{ marginRight: 15 }}>{quantity > 1 ? `${quantity} ITENS` : `${quantity} ITEM`}</Text>
                </Grid>
                <Divider flexItem style={{ backgroundColor: Theme.dark.border }} />
               {
                products && products.length > 0 && !loading ?
                    <Grid container direction='row' marginTop={3} marginBottom={7}>
                        {
                            products
                                .filter(item=>{
                                    if (sexoFilter === 'Masculino' || sexoFilter === 'Feminino'){
                                        if (item.sexo === sexoFilter)
                                            return true
                                    } else {
                                        return true
                                    }
                                })
                                .map((item) =>
                                <ProductCard id={item.cod_prod} key={item.cod_prod} name={item.desc_prod}
                                             price={parseFloat(item.cost)} image={item.link_img01}
                                             addToCart={() => console.log('addtocart')}
                                             onClick={() => navigate(`/produto/${item.cod_prod}`, {state: {product: item}})}/>)
                        }
                    </Grid>
                :
                    <Grid container alignItems='center' justifyContent='center' direction='row' marginTop={3} marginBottom={7}>
                        <CircularProgress size={50} style={{ color: '#fff', marginBottom: 20 }} />
                    </Grid>
               }
{/*                <Grid container justifyContent='center' style={{ marginTop: -50, marginBottom: 60 }}>
                    <Button onClick={listProducts} variant='outlined' style={{ borderWidth: 2, fontWeight: 600, fontSize: 14 }}>
                        CARREGAR MAIS
                    </Button>
                </Grid>*/}
            </Container>
            <ImgContainer image={BgLogin}>
                <Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
                    Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
                </Text>
                <Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
                    Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
                </Text>
            </ImgContainer>
            {/* <Container style={{ marginTop: 35 }}>
                <Text size={24} weight='600'>Destaques</Text>
                <Grid container direction='row' marginTop={3} marginBottom={7}>
                    {
                        category2.map((item, index) => <ProductCard key={index} name={item.productName} price={item.price} image={item.image} addToCart={() => console.log('addtocart')} onClick={() => console.log('onclick')} />)
                    }
                </Grid>
            </Container> */}
        </div>
    )
}

export default ListProducts;
