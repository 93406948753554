import { Drawer, Toolbar, Divider, List, ListItem, ListItemButton, ListItemIcon, Grid } from '@mui/material';
import Theme from '../../style/Theme';
import BittarStoreLogo from '../../assets/images/bittar-store-logo.png';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Text from '../Text';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
    const navigate = useNavigate();
    return (
        <Drawer
            sx={{
            width: '15%',
            flexShrink: 0,
            backgroundColor: Theme.dark.tertiary,
            justifyContent: 'space-between',
            '& .MuiDrawer-paper': {
                width: '15%',
                boxSizing: 'border-box',
                backgroundColor: Theme.dark.tertiary,
                justifyContent: 'space-between',
            },
            }}
            variant="permanent"
            anchor="left"
        >
            <Grid>
                <Toolbar>
                    <img src={BittarStoreLogo} style={{ width: 140, paddingLeft: '5%' }} />
                </Toolbar>
                <Divider style={{ backgroundColor: Theme.dark.border }} />
                <List style={{ paddingLeft: '5%' }}>
                    <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                        <ListItemButton onClick={() => navigate('/admin/dash')}>
                            <ListItemIcon>
                                <GridViewOutlinedIcon sx={{ color: Theme.dark.primary}} />
                            </ListItemIcon>
                            <Text size={14} weight='600'>Dashboard</Text>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                        <ListItemButton onClick={() => navigate('/admin/pedidos')}>
                            <ListItemIcon>
                                <ContentPasteOutlinedIcon sx={{ color: Theme.dark.primary}} />
                            </ListItemIcon>
                            <Text size={14} weight='600'>Pedidos</Text>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                        <ListItemButton onClick={() => navigate('/admin/produtos')}>
                            <ListItemIcon>
                                <ShoppingBagOutlinedIcon sx={{ color: Theme.dark.primary}} />
                            </ListItemIcon>
                            <Text size={14} weight='600'>Produtos</Text>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                        <ListItemButton onClick={() => navigate('/admin/usuarios')}>
                            <ListItemIcon>
                                <PeopleAltOutlinedIcon sx={{ color: Theme.dark.primary}} />
                            </ListItemIcon>
                            <Text size={14} weight='600'>Usuários</Text>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ marginBottom: '10px' }}>
                        <ListItemButton onClick={() => navigate('/admin/configuracoes')}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon sx={{ color: Theme.dark.primary}} />
                            </ListItemIcon>
                            <Text size={14} weight='600'>Configurações</Text>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Grid>
            <ListItem disablePadding sx={{ marginBottom: '10px', paddingLeft: '5%' }}>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <LogoutOutlinedIcon sx={{ color: Theme.dark.primary}} />
                    </ListItemIcon>
                    <Text size={14} weight='600'>Sair</Text>
                </ListItemButton>
            </ListItem>
        </Drawer>
    )
}

export default Menu;