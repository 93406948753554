import { Toolbar, Divider, Grid, Button } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Settings = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Menu />
            <Toolbar>
                <SettingsOutlinedIcon sx={{ color: Theme.dark.primary, paddingLeft: '17%', paddingRight: 1 }} />
                <Text size={24} weight='600'>Configurações</Text>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' direction='row' style={{ width: '70%', marginBottom: '8px' }}>
                    <Text size={18} weight='600'>Produtos</Text>
                    <Divider style={{ backgroundColor: Theme.dark.border, width: '91%' }} />
                </Grid>
                <Text size={14} weight='600' color={Theme.dark.greyLight}>Configure a lista de categorias</Text>
                <Grid container marginTop='10px' sx={{ maxWidth: '80%' }}>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Camisa</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Calça</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Acessórios</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Meias</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Camisa</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Calça</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Acessórios</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Meias</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Button variant='contained' sx={{ padding: '17px', marginTop: '8px', marginBottom: '16px' }}>
                    <Text size={12} weight='600'>+ ADICIONAR NOVA CATEGORIA</Text>
                </Button>
                <Text size={14} weight='600' color={Theme.dark.greyLight}>Configure a lista de tags</Text>
                <Grid container marginTop='10px' sx={{ maxWidth: '80%' }}>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Esportivo</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Casual</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Clássico</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Vintage</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Esportivo</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Casual</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Clássico</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                    <Grid container direction='row' justifyContent='space-between' marginRight='40px' marginBottom='10px' bgcolor={Theme.dark.secondary} borderRadius='8px' sx={{ padding: '12px', border: `1px solid ${Theme.dark.border}`, width: '245px', height: '50px', alignItems: 'center' }}>
                        <Text>Vintage</Text>
                        <DeleteOutlineOutlinedIcon sx={{ color: Theme.dark.danger, cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Button variant='contained' sx={{ padding: '17px', marginTop: '8px', marginBottom: '16px', width: '245px' }}>
                    <Text size={12} weight='600'>+ ADICIONAR NOVA TAG</Text>
                </Button>
            </Grid>
        </div>
    )
}

export default Settings;