import { Container, Grid, Breadcrumbs, Link, Typography, Divider, Button, InputBase } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import Camisa from '../../../assets/images/camisa.jpeg';
import Sueter from '../../../assets/images/sueter.jpeg';
import Jaqueta from '../../../assets/images/jaqueta.jpeg';
import Blusa from '../../../assets/images/blusa.jpeg';
import CartProductCard from '../../../components/CartProductCard';
import ProductCard from '../../../components/ProductCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatNumber } from '../../../utils/formatMoney';

const Cart = () => {
    const navigate = useNavigate();
    const totalCountCart = useStoreState(({ cart }) => cart.totalCart);
    const totalItemsCart = useStoreState(({ cart }) => cart.totalItems);
    const cart = useStoreState(({ cart }) => cart.cart);

    return (
        <Container>
            <Breadcrumbs style={{ fontWeight: '700', color: Theme.dark.border, marginTop: 100, marginBottom: 50, fontSize: 12 }} aria-label="breadcrumb">
                <Link underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' onClick={() => navigate('/produtos')}>
                    Home
                </Link>
                <Typography fontSize={12} fontWeight='700' color={Theme.dark.grey}>Carrinho <spam style={{ color: Theme.dark.border, marginLeft: 6, fontWeight: '700' }}>/</spam></Typography>
            </Breadcrumbs>
            {
                cart.length > 0 && cart.map((item) =>
                    <CartProductCard totalValue={Number(item.cost) * item.quantity} totalItemsCart={totalItemsCart} product={item} id={item.cod_prod} stock={item.stock} quantity={item.quantity} image={item.link_img01} productName={item.desc_prod} price={Number(item.cost)} size={item.size_selected} />
                )
            }
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' marginBottom='39px'>
                <Text size={16} color={Theme.dark.grey} weight='600'>VALOR TOTAL</Text>
                <Text size={24} color={Theme.dark.primaryText} weight='700' style={{ marginRight: 72, marginLeft: 193 }}>R${formatNumber(totalCountCart)}</Text>
            </Grid>
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginBottom: 180 }}>
                <Button onClick={() => navigate('/produtos')} variant="outlined" style={{ width: 241, height: 53, marginRight: 25 }}>
                    <Text size={12} weight='700' color={Theme.dark.primary} style={{ padding: '32px 16px' }}>CONTINUAR COMPRANDO</Text>
                </Button>
                <Button onClick={() => navigate('/finalizar-compra')} variant="contained" style={{ width: 241, height: 53 }}>
                    <Text size={12} weight='700' color={Theme.dark.primaryText} style={{ padding: '32px 16px' }}>IR PARA O PAGAMENTO</Text>
                </Button>
            </Grid>
            {/* <Text size={24} weight='600'>Destaques</Text>
            <Grid container direction='row' marginTop={3} marginBottom={7}>
                {
                    category1.map((item, index) => <ProductCard key={index} name={item.productName} price={item.price} image={item.image} addToCart={() => console.log('addtocart')} onClick={() => console.log('onclick')} />)
                }
            </Grid> */}
            <Grid container alignItems='center' justifyContent='center' marginBottom='32px' marginTop='-50px'>
                <Button endIcon={<ArrowForwardIcon />} onClick={() => navigate('/produtos')}>
                    <Text size={14} weight='700' color={Theme.dark.primary}>VER LOJA COMPLETA</Text>
                </Button>
            </Grid>
        </Container>
    )
}

export default Cart;
