import styled from 'styled-components';

export const ContainerImage = styled.div.attrs((props) => props)`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: black;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: 210%;
  transition: background-size 1s ease-in-out;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
`;