import React from 'react';
import { Divider, Grid } from '@mui/material';
import Text from '../Text';
import Theme from '../../style/Theme';

const ListContainer = (props) => {
    const { sales } = props;
    const status = ['NOVO', 'CANCELADO', 'ENTREGUE', 'ENVIADO'];

    return(
        <Grid container direction='row' sx={{ width: '100%', marginTop: '8px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '21px', backgroundColor: Theme.dark.secondary, borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
            {
                sales && sales.map((item) => (
                    <Grid container sx={{ width: '100%' }} key={item.id}>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%', marginBottom: '10px' }}>
                            <Text size={12} weight='600' style={{ width: '8%' }}>#{item.numberSale}</Text>
                            <Text size={12} weight='400' style={{ width: '24%' }}>{item.buyer}</Text>
                            <Text size={12} weight='500' style={{ width: '20%' }}>{item.city}</Text>
                            <Text size={12} weight='500' style={{ width: '18%' }}>{item.date}</Text>
                            <Text size={12} weight='500' style={{ width: '10%' }} color={Theme.dark.primary}>{item.quantity} item</Text>
                            {
                                item.status === 'NOVO' &&
                                <Grid 
                                    container 
                                    alignItems='center' 
                                    justifyContent='center' 
                                    sx={{ 
                                        width: '100px', 
                                        height: '20px', 
                                        borderRadius: 10, 
                                        border: `1.5px solid ${Theme.dark.primary}` 
                                    }}
                                >
                                    <Text weight='700' size={10} color={Theme.dark.primary}>{item.status}</Text>
                                </Grid>
                            }
                            {
                                item.status === 'ENTREGUE' &&
                                <Grid 
                                    container 
                                    alignItems='center' 
                                    justifyContent='center' 
                                    sx={{ 
                                        width: '100px', 
                                        height: '20px', 
                                        borderRadius: 10, 
                                        border: `1.5px solid ${Theme.green.tertiary}` 
                                    }}
                                >
                                    <Text weight='700' size={10} color={Theme.green.tertiary}>{item.status}</Text>
                                </Grid>
                            }
                            {
                                item.status === 'ENVIADO' &&
                                <Grid 
                                    container 
                                    alignItems='center' 
                                    justifyContent='center' 
                                    sx={{ 
                                        width: '100px', 
                                        height: '20px', 
                                        borderRadius: 10, 
                                        border: `1.5px solid ${Theme.dark.yellow}` 
                                    }}
                                >
                                    <Text weight='700' size={10} color={Theme.dark.yellow}>{item.status}</Text>
                                </Grid>
                            }
                            {
                                item.status === 'CANCELADO' &&
                                <Grid 
                                    container 
                                    alignItems='center' 
                                    justifyContent='center' 
                                    sx={{ 
                                        width: '100px', 
                                        height: '20px', 
                                        borderRadius: 10, 
                                        border: `1.5px solid ${Theme.dark.danger}` 
                                    }}
                                >
                                    <Text weight='700' size={10} color={Theme.dark.danger}>{item.status}</Text>
                                </Grid>
                            }
                        </Grid>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '10px', width: '100%' }} />
                    </Grid>
                ))
            }
        </Grid>
    )
};

export default ListContainer;