import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import * as Styled from './styles';
import { Grid } from '@mui/material';

const Input = ({ onChange, forwardedRef = null, style, isPassword, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeVisibility = () => setShowPassword(!showPassword);

  return isPassword ? (
    <Styled.PasswordContainer
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Styled.inputPassword
        {...props}
        type={showPassword === true ? 'text' : 'password'}
        onChange={(value) => onChange(value?.target?.value)}
        style={{ ...style }}
        ref={forwardedRef}
      />
      {showPassword ? <VisibilityOff style={{ color: '#C6C8CA' }} onClick={handleChangeVisibility} /> : <Visibility style={{ color: '#C6C8CA' }} onClick={handleChangeVisibility} /> }
    </Styled.PasswordContainer>
  ) : (
    <Styled.TextField
      {...props}
      onChange={(value) => onChange(value?.target?.value)}
      ref={forwardedRef}
      style={{ ...style }}
    />
  );
};

Input.defaultProps = {
  defaultValue: '',
  label: '',
  placeholder: '',
  type: 'text',
  variant: 'filled',
  style: {}
};

Input.prototype = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object
};

export default Input;
