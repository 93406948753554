import React from 'react';
import { Grid, DialogContent, Dialog, DialogContentText, DialogActions, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	Article,
	Book,
	CalendarToday,
	Close,
	Feedback,
	HomeOutlined,
	Group,
	ImportContacts,
	Logout,
	RecordVoiceOver,
	TrendingUp,
	Warning,
	ChatBubbleOutline,
	PrivacyTip
} from '@mui/icons-material';

import Text from '../../../Text';
import Theme from '../../../../style/Theme';

const MENU = [
	{
		icon: HomeOutlined,
		title: 'Início',
		path: '/login',
	},
	{
		icon: ImportContacts,
		title: 'Aulas',
		path: '/aulas',
	},
	{
		icon: Warning,
		title: 'Avisos',
		path: '/avisos',
	},
	{
		icon: TrendingUp,
		title: 'Meu desempenho',
		path: '/desempenho',
	},
	{
		icon: CalendarToday,
		title: 'Meu calendário',
		path: '/calendario',
	},
	// {
	// 	icon: FilterCenterFocus,
	// 	title: 'Meu registro de pontos',
	// 	path: '/registros',
	// },
	{
		icon: RecordVoiceOver,
		title: 'Falar com o Instituto',
		path: '/instituto',
	},
	{
		icon: TrendingUp,
		title: 'Registro de ponto',
		path: '/registro-ponto',
	},
	{
		icon: ChatBubbleOutline,
		title: 'Mensagens',
		path: '/chat-alunos',
	},
	{
		icon: Book,
		title: 'Relatório de atividades práticas',
		path: '/praticas',
	},
	{
		icon: Group,
		title: 'Acompanhamento psicossocial',
		path: '/psicossocial',
	},
	{
		icon: Feedback,
		title: 'Falar com a Ouvidoria',
		path: '/ouvidoria',
	},
	{
		icon: Article,
		title: 'Termos e condições',
		path: '/termos',
	},
	{
		icon: PrivacyTip,
		title: 'Política de Privacidade',
		path: '/politica-protecao-dados',
	},
	{
		icon: Logout,
		title: 'Sair',
		path: '',
	}
]

const DrawerContent = ({ onLogout }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;
	const [dialogLogout, setDialogLogout] = React.useState(false);

	const handleOpenDialogLogout = () => setDialogLogout(true);
	const handleCloseDialogLogout = () => setDialogLogout(false);

	const handleLogout = () => {
		handleOpenDialogLogout();
	};

	const handleClick = (item) => {
		if (item.title === 'Sair') {
			handleLogout()
		}
		navigate(item.path);
	};

	const logout = () => {
		handleCloseDialogLogout();

		localStorage.clear();
		navigate('/');
		window.location.replace('/');
	};

	return (
		<Grid
			container
			direction="column"
			style={{ maxWidth: 350, width: '100%', height: '100%', backgroundColor: Theme.dark.secondary, padding: 15, overflowY: 'auto', display: 'flow-root' }}
		>
			<Dialog
				open={dialogLogout}
				keepMounted
				onClose={handleCloseDialogLogout}
				aria-describedby="logout-dialog"
			>
				<DialogContent style={{ backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
						Você realmente deseja sair?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<Button onClick={handleCloseDialogLogout}>Cancelar</Button>
					<Button onClick={logout}>Sair</Button>
				</DialogActions>
			</Dialog>

			{
				MENU.map((item, index) => {
					let actived = path === item.path;
					return (
						<div style={{ marginTop: 5, padding: 10, backgroundColor: actived ? `${Theme.dark.primary}33` : 'transparent', borderRadius: 8 }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								style={{ cursor: 'pointer' }}
								title={item.title}
								onClick={() => handleClick(item)}
							>
								<item.icon style={{ color: actived ? Theme.dark.primary : Theme.dark.primary }} />
								<Text
									color={actived ? Theme.dark.primary : Theme.dark.primaryText}
									size={15}
									style={{ marginLeft: 15, flex: 1 }}
								>
									{item.title}
								</Text>
							</Grid>
						</div>
					)
				})
			}
		</Grid>
	);
};

export default DrawerContent;
