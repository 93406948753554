import { Grid, Toolbar, Divider, Button, InputBase } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ListDataUserContainer from '../../../components/ListDataUserContainer';
import ListItemsSaleContainer from '../../../components/ListItemsSaleContainer';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const SaleDetail = () => {
    const navigate = useNavigate();
    const products = [
        { id: 1, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 3, price: '145,00' },
        { id: 2, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 3, price: '145,00' },
        { id: 3, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 2, price: '145,00' },
        { id: 4, numberSale: '1234', productName: 'Calça social', size: 'G', quantity: 2, price: '145,00' },
        { id: 5, numberSale: '1234', productName: 'Calça social', size: 'G', quantity: 3, price: '145,00' },
        { id: 6, numberSale: '1234', productName: 'Calça social', size: 'G', quantity: 2, price: '145,00' },
        { id: 7, numberSale: '1234', productName: 'Calça social', size: 'G', quantity: 3, price: '145,00' },
        { id: 8, numberSale: '1234', productName: 'Calça social', size: 'G', quantity: 2, price: '145,00' },
        { id: 9, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 3, price: '145,00' },
        { id: 10, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 2, price: '145,00' },
        { id: 11, numberSale: '1234', productName: 'Camisa social', size: 'G', quantity: 2, price: '145,00' }
    ];

    return (
        <div>
            <Menu />
            <Toolbar>
                <ContentPasteOutlinedIcon sx={{ color: Theme.dark.primary, paddingLeft: '17%', paddingRight: 1 }} />
                <Text size={24} weight='600'>Pedido</Text>
                <Divider orientation='vertical' flexItem variant='middle' sx={{ width: '1px', backgroundColor: 'white', marginRight: '10px', marginLeft: '10px' }} />
                <Text size={24} weight='600'>#1234</Text>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Text size={18} weight='600'>Lucas Brás</Text>
                        <Button>
                            <LocalPrintshopOutlinedIcon sx={{ color: Theme.dark.primary, paddingRight: 1 }} />
                            <Text size={18} color={Theme.dark.primary} weight='600'>Imprimir</Text>
                        </Button>
                    </Grid>
                    <Text size={12} weight='500' style={{ fontStyle: 'italic' }}>Designer UI/UX</Text>
                    <Grid container sx={{ marginTop: '20px' }}>
                        <Text size={14} weight='600'>Dados do cliente</Text>
                        <ListDataUserContainer />
                        <Divider style={{ backgroundColor: Theme.dark.border, marginTop: '40px', marginBottom: '20px', width: '100%' }} />
                    </Grid>
                    <Grid container sx={{ marginTop: '20px' }}>
                        <Text size={14} weight='600'>Itens do pedido</Text>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%', marginTop: '15px' }}>
                            <Text size={10} weight='600' color={Theme.dark.greyLight} style={{ width: '30%', marginLeft: 15 }}>Produto</Text>
                            <Text size={10} weight='600' color={Theme.dark.greyLight} style={{ width: '20%', marginLeft: 80 }}>Tamanho</Text>
                            <Text size={10} weight='600' color={Theme.dark.greyLight} style={{ width: '20%', marginLeft: 0 }}>Quantidade</Text>
                            <Text size={10} weight='600' color={Theme.dark.greyLight} style={{ width: '20%', marginRight: 15 }}>Valor</Text>
                        </Grid>
                        <ListItemsSaleContainer sales={products} />
                    </Grid>
                </Grid>
                <Grid container direction='column' style={{ width: '30%' }}>
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Status do pedido</Text>
                    </Grid>
                    <Grid container direction='row' justifyContent='center' sx={{ width: '70%', marginLeft: 1, marginTop: 2 }}>
                        <Grid container alignItems='flex-start' justifyContent='center' direction='row' sx={{ width: '100%' }}>
                            <Grid container alignItems='flex-start' justifyContent='center' direction='row' sx={{ width: '100%' }}>
                                <Grid container alignItems='center' direction='column' sx={{ width: '30%' }}>
                                    <Grid style={{ backgroundColor: Theme.green.tertiary, width: '16px', height: '16px', borderRadius: '16px' }} />
                                    <Grid style={{ backgroundColor: Theme.green.tertiary, width: '4px', height: '100px' }} />
                                </Grid>
                                <Grid container alignItems='flex-start' direction='column' sx={{ width: '70%' }}>
                                    <Text size={14} weight='500'>
                                        Novo pedido feito no dia:
                                    </Text>
                                    <Text size={18} weight='500'>
                                        16/09/2022
                                    </Text>
                                </Grid>
                            </Grid>
                            <Grid container alignItems='flex-start' justifyContent='center' direction='row' sx={{ width: '100%' }}>
                                <Grid container alignItems='center' direction='column' sx={{ width: '30%' }}>
                                    <Grid style={{ backgroundColor: Theme.green.tertiary, width: '16px', height: '16px', borderRadius: '16px' }} />
                                    <Grid style={{ backgroundColor: Theme.dark.tertiary, width: '4px', height: '100px' }} />
                                </Grid>
                                <Grid container alignItems='flex-start' direction='column' sx={{ width: '70%' }}>
                                    <Text size={14} weight='500'>
                                    Codigo de rastreio para status enviado:
                                    </Text>
                                    <div style={{ width: '100%', height: '38px', display: 'flex', backgroundColor: Theme.dark.border, alignItems: 'center', border: `2px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: '10px' }}>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                        />
                                        <Button style={{ padding: 2, backgroundColor: Theme.dark.primary, marginRight: '-2px', width: '20%', height: '41px', borderRadius: '8px', border: `2px solid ${Theme.dark.primary}` }}>
                                           <Text>OK</Text>
                                           <ArrowForwardOutlinedIcon sx={{ width: '20px', height: '20px', color: 'white' }} />
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container alignItems='flex-start' justifyContent='center' direction='row' sx={{ width: '100%' }}>
                                <Grid container alignItems='center' direction='column' sx={{ width: '30%' }}>
                                    <Grid style={{ backgroundColor: Theme.dark.tertiary, width: '16px', height: '16px', borderRadius: '16px' }} />
                                    <Grid style={{ backgroundColor: Theme.dark.tertiary, width: '4px', height: '100px' }} />
                                </Grid>
                                <Grid container alignItems='flex-start' direction='column' sx={{ width: '70%' }}>
                                    <Text size={14} weight='500' color={Theme.dark.tertiary}>
                                    Pedido enviado para os correios
                                    </Text>
                                    <Text size={14} weight='500' color={Theme.dark.tertiary}>
                                    20/07/2022
                                    </Text>
                                    <Text size={18} weight='500' color={Theme.dark.tertiary}>
                                    SL123456789BR
                                    </Text>
                                </Grid>
                            </Grid>
                            <Grid container alignItems='flex-start' justifyContent='center' direction='row' sx={{ width: '100%' }}>
                                <Grid container alignItems='center' direction='column' sx={{ width: '30%' }}>
                                    <Grid style={{ backgroundColor: Theme.dark.tertiary, width: '16px', height: '16px', borderRadius: '16px' }} />
                                </Grid>
                                <Grid container alignItems='flex-start' direction='column' sx={{ width: '70%' }}>
                                    <Text size={14} weight='500' color={Theme.dark.tertiary}>
                                    Pedido entregue no dia
                                    </Text>
                                    <Text size={18} weight='500' color={Theme.dark.tertiary}>
                                    28/07/2022
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SaleDetail;