import { Toolbar, Grid, Divider, Button, TextField } from '@mui/material';
import React from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ListProductContainer from '../../../components/ListProductContainer';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

const Products = () => {
    const navigate = useNavigate();
    const products = [
        { id: 1, numberSale: '1234', productName: 'Camisa social', unidade: 'Bittar Educação', gender: 'Masculino', quantityVariations: 5 },
        { id: 2, numberSale: '1234', productName: 'Camisa social', unidade: 'Bittar Educação', gender: 'Masculino', quantityVariations: 5 },
        { id: 3, numberSale: '1234', productName: 'Camisa social', unidade: 'IJB', gender: 'Feminino', quantityVariations: 5 },
        { id: 4, numberSale: '1234', productName: 'Calça social', unidade: 'Bittar Educação', gender: 'Feminino', quantityVariations: 5 },
        { id: 5, numberSale: '1234', productName: 'Calça social', unidade: 'IJB', gender: 'Masculino', quantityVariations: 5 },
        { id: 6, numberSale: '1234', productName: 'Calça social', unidade: 'Bittar Educação', gender: 'Feminino', quantityVariations: 5 },
        { id: 7, numberSale: '1234', productName: 'Calça social', unidade: 'Bittar Educação', gender: 'Masculino', quantityVariations: 5 },
        { id: 8, numberSale: '1234', productName: 'Calça social', unidade: 'IJB', gender: 'Feminino', quantityVariations: 5 },
        { id: 9, numberSale: '1234', productName: 'Camisa social', unidade: 'IJB', gender: 'Masculino', quantityVariations: 5 },
        { id: 10, numberSale: '1234', productName: 'Camisa social', unidade: 'Bittar Educação', gender: 'Feminino', quantityVariations: 5 },
        { id: 11, numberSale: '1234', productName: 'Camisa social', unidade: 'Bittar Educação', gender: 'Feminino', quantityVariations: 5 }
    ];

    return (
        <div>
            <Menu />
            <Toolbar>
                <Grid container direction='row' alignItems='center' style={{ paddingLeft: '17%', width: '40%' }}>
                    <ShoppingBagOutlinedIcon sx={{ color: Theme.dark.primary, paddingRight: 1 }} />
                    <Text size={24} weight='600'>Produtos</Text>
                </Grid>
                <Grid container direction='row' alignItems='center' style={{ width: '50%' }}>
                    <Button>
                        <SearchOutlinedIcon />
                    </Button>
                    <TextField id="standard-basic" label="Buscar pedido" variant="standard" inputProps={{ style: { borderBottom: `1px solid ${Theme.dark.greySuperLight}` } }} InputLabelProps={{ style: { color: Theme.dark.greyLight } }} sx={{ width: '65%', marginBottom: 2 }} />
                </Grid>
                <Grid container direction='row' alignItems='center' style={{ width: '10%' }}>
                    <Button sx={{ width: '133px', height: '31px', padding: '8px' }} variant='contained'>
                        <Text size={12} weight='700'>NOVO PRODUTO</Text>
                    </Button>
                </Grid>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='flex-start' alignItems='center'>
                        <Text size={18} weight='600'>Lista de produtos</Text>
                    </Grid>
                    <ListProductContainer products={products} />
                </Grid>
                <Grid container direction='column' style={{ width: '30%' }}>
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Notificações</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
                        <Text size={10} weight='500'>O item <spam style={{ fontWeight: 'bold', color: Theme.dark.primary }}>Camiseta azul</spam>, está com o estoque baixo!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>3 Peças</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.danger}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#03215 Lucas Brás</spam>, está atrasado do prazo de entrega!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>2 Dias</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.yellow}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#1562 Arlene McCoy</spam>, precisa ser enviado para os correios!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Products;