import api from 'apisauce';

export const API_URL = process.env.REACT_APP_API_PATH;
export const API = () => {

  let tokenLocal = localStorage.getItem('access_token');

  let result =  api.create({
    baseURL: `${API_URL}/api`,
    headers: {
      'content-type': 'application/json',
      'app-version': 'WEB',
      platform: 'WEB',
      authorization: 'Bearer '+ tokenLocal
    },
    timeout: 30000,
  });

  return result;
}
export default API;
