import {Breadcrumbs, Container, Grid, Link,Item,Skeleton} from '@mui/material';
import React, {useState} from 'react';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import {useLocation, useNavigate} from 'react-router';
import StepWizard from "react-step-wizard";
import 'animate.css'

import One from './components/One'
import Two from './components/Two'
import Three from "./components/Three";
import {postChangeProduct} from "../../../api/CheckoutProduct";
import {useStoreState} from "easy-peasy";
import {toast} from "react-toastify";
const Change = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [nrCodChange, setNrCodChange] = useState(0);
    const [stepWizard, setStepWizard] = useState(null);
    const location = useLocation()
    const { purchase } = location.state;
    const user = useStoreState(({ user }) => user.user);
    const [disableButton, setDisableButton] = useState(true);

    const navigate = useNavigate();
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };
    const handleStepChange = (e) => {
        console.log(e);
        setActiveStep(e.activeStep - 1);
    };

    let custom = {
        enterRight: 'animate__animated animate__zoomIn',
        enterLeft: 'animate__animated animate__zoomIn',
        exitRight: 'animate__animated animate__zoomOut',
        exitLeft: 'animate__animated animate__zoomOut'
    }
    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };
    const handleCreateChange = async () => {
        try {
            let response = await postChangeProduct({ id: purchase.id, nome_funcionario: user.name });

            if(response.status === 200) {
                setNrCodChange(response.data.nr_change);
            } else if (response.status === 403) {
                logout();
            } else {
                toast('Não foi possível efetivar a sua solicitação de troca, favor procurar os administradores do sistema.',{ type: 'error' })
            }
        } catch (e) {
            console.log(e)
            toast('Não foi possível efetivar a sua solicitação de troca, favor procurar os administradores do sistema.',{ type: 'error' })
        } finally {
            setDisableButton(false)
        }
    }

    return (
        <Container>
            <Breadcrumbs style={{fontWeight: '700', color: Theme.dark.border, marginTop: 32, fontSize: 12, marginBottom:25}}
                         aria-label="breadcrumb">
                <Link underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700'
                      onClick={() => navigate('/home')}>
                    Home
                </Link>
                <Link underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700'
                      onClick={() => navigate('/compras')}>
                    Compras
                </Link>
                <Text size={12} weight='700' color={Theme.dark.grey}> Troca </Text>
            </Breadcrumbs>
            <StepWizard transitions={ custom } instance={ assignStepWizard } onStepChange={ handleStepChange }>
                <One purchase={ purchase }/>
                <Two purchase={ purchase } onChange={ handleCreateChange }  />
                <Three disableButton={ disableButton } purchase={ purchase } codChange={ nrCodChange }/>
            </StepWizard>
        </Container>
    )
}

export default Change;
