import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Theme from '../../style/Theme';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 100,
  bgcolor: Theme.dark.background,
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

export default function LoadingModal(props) {
  const { open, setOpen } = props;
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100, borderRadius: 20 }}>
            <CircularProgress style={{ color: '#fff' }} size={80} />
        </Box>
      </Modal>
    </div>
  );
}
