import {
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    InputBase,
    Link,
    Modal
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import EmptyImage from '../../../assets/images/empty-image.png';
import {formatNumber} from '../../../utils/formatMoney';
import {useNavigate} from 'react-router';
import {useStoreActions, useStoreState} from 'easy-peasy';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {getProduct} from "../../../api/Product";

const ProductDetail = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const location = useLocation()

    const [product, setProduct] = useState({});
    const [control,setControl ] = useState([]);
    const totalItemsCart = useStoreState(({ cart }) => cart.totalItems);
    const totalCountCart = useStoreState(({ cart }) => cart.totalCart);
    const cart = useStoreState(({ cart }) => cart.cart);
    const addToCart = useStoreActions(({ cart }) => cart.addToCart);
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState('');

    const [quantity, setQuantity] = useState(1);
    const [size, setSize] = useState(null);
    const [stock, setStock] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogStock, setDialogStock] = useState(false);
    const handleOpenDialogStock = () => setDialogStock(true);
    const handleCloseDialogStock = () => {
        setDialogStock(false)
        addCart();
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && quantity) {
            setQuantity(event.target.value)
        }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        borderRadius: 5,
        cursor: 'pointer'
    };

    const handleAddToCart = async () => {
        if(!size) {
            return toast('Você precisa selecionar um tamanho!', { type: 'error' })
        }

        let productSelected = product.control.find(prod => size === prod.size);

        if(productSelected.stock < quantity){
            handleOpenDialogStock();
        }else{
            addCart()
        }
    }

    const addCart = async () => {

        const prod = {
            ...product,
            quantity,
            size_selected: size,
            stock
        };

        const newCart = cart.length > 0 ? [...cart, prod] : [prod];
        const newQuantity = quantity + totalItemsCart;
        const newValue = (Number(prod.cost) * quantity) + totalCountCart;
        await addToCart({
            cart: newCart,
            totalValue: newValue,
            totalItems: newQuantity
        })
        toast('Produto adicionado ao carrinho!', { type: 'success' })
    }


    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };

    const handleGetProduto = async () => {

        try{
            const response = await getProduct(id);
            if (response.status === 200){
                setProduct(response.data);
                setControl(response.data.control)
            } else if (response.status === 403) {
                logout();
            }
        }catch (e) {
            setMessageError('Ops! Ocorreu um problema.')
            setError(true)
            console.log(e)
        }
    };

    let images = [];

    if(product.link_img01 !== null )
        images.push(product.link_img01)

    if(product.link_img02 !== null )
        images.push(product.link_img02)

    if(product.link_img03 !== null )
        images.push(product.link_img03)

    if(product.link_img04 !== null )
        images.push(product.link_img04)

    useEffect(() => {
        handleGetProduto()
        window.scrollTo(0, 0)
    }, []);

    return (
        <Grid container>
            <Grid container direction='row'>
                <Grid xs={12} sm={6}>
                    <Carousel showThumbs={false} showStatus={false}>
                        {
                            images.length > 0 ? images.map((item) => <img src={item !== null ? item : EmptyImage} style={{ width: '100%', height: 800, objectFit: 'cover' }} />)
                                :
                                <img src={EmptyImage} style={{ width: '100%', height: 800, objectFit: 'cover' }} />
                        }
                    </Carousel>
                </Grid>

                <Grid xs={12} sm={6}>
                    <Breadcrumbs style={{ marginLeft: 120, fontWeight: '700', color: Theme.dark.border, marginTop: 120, marginBottom: 10, fontSize: 12 }} aria-label="breadcrumb">
                        <Link style={{ cursor: 'pointer' }} underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' onClick={() => navigate('/produtos')}>
                            Home
                        </Link>
                        <Link style={{ cursor: 'pointer' }} underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' onClick={() => navigate('/produtos')}>
                            Produtos
                        </Link>
                    </Breadcrumbs>
                    <Text size={36} weight='600' style={{ marginLeft: 120 }}>{product.desc_prod}</Text>
                    <div style={{ maxWidth: '70%', marginTop: 15, marginBottom: 30 }}>
                        <Text size={16} weight='500' style={{ marginLeft: 120 }}>{product.desc_prod}</Text>
                    </div>
                    <Grid container direction='column' width='70%'>
                        <Grid container direction='row' justifyContent='space-between' marginLeft='120px'>
                            <Text size={14} weight='600'>Tamanho</Text>
                            <Text size={14} weight='600' color={Theme.dark.primary} style={{ cursor: 'pointer' }} onClick={handleOpen}>Guia de tamanhos</Text>
                        </Grid>
                        <Grid container direction='row' justifyContent='space-between' marginLeft='120px' marginTop='10px'>
                            {
                                control.map((item) =>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '80px', alignItems: 'center' }}>
                                        <Grid
                                            style={{ cursor: 'pointer', backgroundColor: item.size === size ? Theme.dark.primary : null }}
                                            key={item.size}
                                            onClick={() => {
                                                setSize(item.size)
                                                setStock(item.stock)
                                            }}
                                            container
                                            justifyContent='center'
                                            alignItems='center'
                                            border={item.size === size ? `1px solid ${Theme.dark.primary}` : `1px solid ${Theme.dark.border}`}
                                            borderRadius='8px'
                                            maxWidth='100px'
                                            height='52px'
                                        >
                                            <Text size={24} weight='600'>{item.size}</Text>
                                        </Grid>
                                    </div>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Divider flexItem style={{ backgroundColor: Theme.dark.detail, marginTop: 25, marginLeft: 120, width: '70%' }} />
                    <Text size={14} weight='600' color={Theme.dark.primaryText} style={{ marginBottom: 8, marginLeft: 120, marginTop: 20 }}>Quantidade</Text>
                    <div style={{ width: 168, height: '52px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginLeft: 120}}>
                        <Button onClick={() => setQuantity(quantity === 1 ? 1 : quantity-1)}>
                            <Text size={24} color={Theme.dark.primary} weight='400'>-</Text>
                        </Button>
                        <InputBase
                            sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                            value={quantity}
                            onChange={(event) => setQuantity(event.target.value)}
                            onKeyDown={(event) => handleKeyDown(event)}
                            style={{ fontSize: 24, fontWeight: '600', color: 'white', alignItems: 'center', justifyContent: 'center' }}
                        />
                        <Button onClick={() => setQuantity( quantity === 2 ? 2 : quantity + 1)}>
                            <Text size={24} color={Theme.dark.primary} weight='400'>+</Text>
                        </Button>
                    </div>
                    <Divider flexItem style={{ backgroundColor: Theme.dark.detail, marginTop: 25, marginLeft: 120, marginBottom: 60, width: '70%' }} />
                    <Grid container direction='row' width='70%' marginLeft='120px' justifyContent='space-between' alignItems='center' marginBottom='120px'>
                        <Text size={24} weight='700'>R${formatNumber(product.cost)}</Text>
                        <Button variant="contained" style={{ width: 233, height: 50 }} disableElevation onClick={handleAddToCart}>
                            <Text size={12} weight='700'>ADICIONAR AO CARRINHO</Text>
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <img onClick={handleClose} src={'https://uniformes-bittar-store.s3.us-west-2.amazonaws.com/imagens/tamanhos/'+ product.cod_prod +'.jpg'} style={style} />
            </Modal>
            <Dialog
                open={dialogStock}
                keepMounted
                maxWidth={'xs'}
                onClose={handleCloseDialogStock}
                aria-describedby="logout-dialog"
            >
                <DialogContent style={{ backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
                    <DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
                        No momento não temos o produto em estoque do tamanho {size} e por este motivo a entrega do mesmo pode atrasar.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
                    <Button onClick={handleCloseDialogStock}>OK</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default ProductDetail;
