import {Grid} from "@mui/material";
import Text from "../../../../components/Text";
import Theme from "../../../../style/Theme";
import PackageBox from "../../../../assets/images/package-box.png";
import ProductCardPurchasing from "../../../../components/ProductCardPurchasing";
import Button from "@mui/material/Button";
import ArrowRightWhite from "../../../../assets/images/arrow-right-white.png";
import React from "react";

const One = (props) => {

    const { purchase } = props;

    return (
        <Grid>
            <Grid marginBottom='24px'>
                <Text size={24} weight='bold'>Embale o produto da mesma forma que você o recebeu</Text>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start"  >

                <Grid  item xs={12} md={7}>
                    <Grid  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                        <Grid container height='70px'>
                            <Grid xs={2} md={1.5} height='70px'>
                                <img style={{margin: '12px 0 0 12px'}} src={PackageBox} />
                            </Grid>
                            <Grid xs={10} md={10.5} height='70px' paddingTop={2.6}>
                                <Text size={24} weight={500}>Daremos um código de troca grátis</Text>
                            </Grid>
                        </Grid>
                        <Grid height='90px' paddingTop='12px' paddingLeft='12px'>
                            <Text size={16}>Você poderá ir a unidade, Rua Economista, 550, Bloco B, Bairro Planalto</Text>
                            <Text size={16}>Uberlândia-MG, CEP: 38413-144, para entregar o produto.</Text>
                            <Text size={16} style={{marginTop:'12px'}}>Não esqueça de escrever o código no pacote!</Text>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid  item xs={12} md={5} paddingLeft="24px" >
                    <Grid  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                        <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                            <Text style={{marginLeft: '7px'}}>Detalhes da compra</Text>
                        </Grid>
                        <Grid container>
                            <Grid xs={4} md={3.4}>
                                <ProductCardPurchasing image={purchase.foto} />
                            </Grid>
                            <Grid xs={8} md={8.6}>
                                <Grid style={{marginTop: 14}}>
                                    <Text size={16} color={Theme.dark.green}>Entregue</Text>
                                </Grid>
                                <Grid style={{marginTop: 14}}>
                                    <Text size={20} color={Theme.dark.primaryText}>{purchase.desc_prod}</Text>
                                    <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{purchase.quantity} unid | Tam: {purchase.size_selected} </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button variant="contained" style={{ width: 233, height: 50, margin: '116px 0 116px 0' }} onClick={() => props.nextStep()}>
                    <Text size={16} weight='500'>AVANÇAR</Text>
                    <img style={{marginLeft: '15px'}} src={ArrowRightWhite}/>
                </Button>
            </Grid>
        </Grid>
    );
};
export default One;
