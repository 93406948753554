import styled from 'styled-components';
import { Grid } from '@mui/material';

import BackgroundImage from '../../../assets/images/background-login.png';

export const Container = styled(Grid)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  flex: 1;
  display: flex;
  max-height: 100vh;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  @media (max-width: 899px) {
    display: none;
  }
`;

export const Form = styled.form`
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;
`;


