import React from 'react';
import { Button, Divider, Grid } from '@mui/material';
import Text from '../Text';
import Theme from '../../style/Theme';
import EditIcon from '../../assets/images/edit-icon.png';

const ListUserContainer = (props) => {
    const { users } = props;

    return(
        <Grid container direction='row' sx={{ width: '100%', marginTop: '8px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '21px', backgroundColor: Theme.dark.secondary, borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
            {
                users && users.map((item) => (
                    <Grid container sx={{ width: '100%' }} key={item.id}>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%', marginBottom: '10px' }}>
                            <Text size={12} weight='600' style={{ width: '25%' }}>{item.username}</Text>
                            <Text size={12} weight='500' style={{ width: '20%' }}>{item.unidade}</Text>
                            <Text size={12} weight='500' style={{ width: '20%' }}>{item.ocupation}</Text>
                            <Text size={12} weight='500' color={Theme.dark.greyLight} style={{ width: '18%' }}>{item.lastAccess}</Text>
                            <Text size={12} weight='500' style={{ width: '10%' }} color={Theme.dark.primary}>{item.status}</Text>
                            <Grid sx={{ width: '7%' }}>
                                <Button>
                                    <img src={EditIcon} style={{ width: '12px' }} />
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '10px', width: '100%' }} />
                    </Grid>
                ))
            }
        </Grid>
    )
};

export default ListUserContainer;