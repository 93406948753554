import {Grid} from "@mui/material";
import Theme from "../../../../style/Theme";
import Text from "../../../../components/Text";
import ProductCardPurchasing from "../../../../components/ProductCardPurchasing";
import Button from "@mui/material/Button";
import React from "react";

const Two = (props) => {

    const { purchase, onChange } = props;

    return (
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" >
            <Grid container item xs={12} md={7}>
                <Grid container padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Grid container height='70px'>
                        <Grid xs={10} md={10.5} height='70px' paddingTop={2.6}>
                            <Text size={24} weight={500}>Antes de iniciar a devolução, observe:</Text>
                        </Grid>
                    </Grid>
                    <Grid container height='110px' paddingTop='12px' paddingLeft='12px'>
                        <ul style={{fontSize:18,color: `${Theme.dark.primary}`,listStyleImage: 'none', padding: 0, marginTop:-2, marginLeft:14}}>
                            <li>
                                <Text style={{marginTop: 5}} weight={400} size={16} color={Theme.dark.grey}>O produto deve estar sem uso, com todos os seus acessórios e no pacote original;</Text>
                            </li>
                            <li>
                                <Text style={{marginTop: 5}} weight={400} size={16} color={Theme.dark.grey}>As devoluções serão conferidas para garantir conformidade em ambos os lados.</Text>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
            <Grid  item xs={12} md={5} paddingLeft="24px" >
                <Grid  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                        <Text style={{marginLeft: '7px'}}>Detalhes da compra</Text>
                    </Grid>
                    <Grid container>
                        <Grid xs={4} md={3.4}>
                            <ProductCardPurchasing image={purchase.foto} />
                        </Grid>
                        <Grid xs={8} md={8.6}>
                            <Grid style={{marginTop: 14}}>
                                <Text size={16} color={Theme.dark.green}>Entregue</Text>
                            </Grid>
                            <Grid style={{marginTop: 14}}>
                                <Text size={20} color={Theme.dark.primaryText}>{purchase.desc_prod}</Text>
                                <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{purchase.quantity} unid | Tam: {purchase.size_selected} </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Button variant="contained" style={{ width: 233, height: 50, margin: '116px 0 116px 0' }} onClick={() =>{ props.nextStep(); onChange();}}>
                <Text size={16} weight='500'>Ok, entendi</Text>
            </Button>
        </Grid>
    );
};

export default Two;
