import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import { setToken } from '../../../api';
import { login } from '../../../api/Auth';

import { ValidateCPF } from '../../../services/Validation';

export default {
  loading: false,

  setLoading: action((state, payload) => {
    state.loading = !!payload;
  }),

  login: thunk( async (actions, { cpf, password }, { getStoreActions }) => {
    if (!ValidateCPF(cpf)) {
      return toast('CPF inválido', { type: 'error' });
    } else if (!password || password.length < 6) {
      return toast('Senha inválida', { type: 'error' });
    }

    actions.setLoading(true);

    await login({ cpf, password }).then(async (response) => {
      if (response.status === 200) {
        await localStorage.setItem('USER', JSON.stringify(response.data));
  
        const { user } = await getStoreActions();
        user.setUser(response.data);
        actions.setLoading(false);
        return true;
      }
  
      let description = response?.data?.message || 'Ocorreu um erro inesperado.';

      toast(description, { type: 'error' });
      
      actions.setLoading(false);
      return false;
    });
  }),
};
