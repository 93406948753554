import { Grid, Divider, Button, InputBase } from '@mui/material';
import React, { useState } from 'react';
import Text from '../Text';
import Theme from '../../style/Theme';
import EmptyImage from '../../assets/images/empty-image.png';
import { formatNumber } from '../../utils/formatMoney';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router';

const CartProductCard = (props) => {
    const { quantity, image, productName, size, price, stock, id, product, totalValue} = props;
    const navigate = useNavigate();

    const updateItems = useStoreActions(({ cart }) => cart.updateTotalItems);
    const totalItemsCart = useStoreState(({ cart }) => cart.totalItems);
    const totalCountCart = useStoreState(({ cart }) => cart.totalCart);

    const cart = useStoreState(({ cart }) => cart.cart);

    const [newQuantity, setNewQuantity] = useState(quantity);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && quantity) {
            setNewQuantity(event.target.value)
        }
    }

    const handleAddMore = (type) => {
        let indexProd = cart.findIndex((item) => item.cod_prod === id && item.size_selected === size)

        if(type === 'SUB'){
            setNewQuantity(newQuantity === 1 ? 1 : newQuantity - 1)
            cart[indexProd].quantity = newQuantity === 1 ? 1 : newQuantity - 1;
            updateItems({
                totalItems: newQuantity === 1 ? totalItemsCart : Number(totalItemsCart) - 1,
                products: cart,
                totalValue: (totalCountCart - Number(product.cost))
            })
        } else {
            setNewQuantity(newQuantity === 2 ? 2 : newQuantity + 1)
            cart[indexProd].quantity = newQuantity === 2 ? 2 : newQuantity + 1;
                updateItems({
                    totalItems:  newQuantity === 2 ? totalItemsCart : Number(totalItemsCart) + 1,
                    products:  cart,
                    totalValue: newQuantity === 2 ? totalCountCart : (totalCountCart + Number(product.cost))
                })

        }
    }

    const handleRemoveProduct = (idProd,sizeProd) => {

        const cartCout = cart && cart.filter((item) => item.cod_prod === idProd);

        let cartFiltered = [];

        if( cartCout.length > 1 ){
            cartFiltered = cart && cart.filter((item) => item.cod_prod === idProd && item.size_selected !== sizeProd);
        } else {
            cartFiltered = cart && cart.filter((item) => item.cod_prod !== idProd);
        }

        if(cartFiltered.length === 0) {
            updateItems({
                totalItems: 0,
                products: [],
                totalValue: 0
            })
    
            navigate('/carrinho')
        }
        
        let newArrayOfValue = [];
        let newArrayOfQuantity = [];
        cartFiltered.map((item) => {
            const totalValue = item.quantity * Number(item.cost);
            newArrayOfValue.push(totalValue)
        });
        cartFiltered.map((item) => {
            const totalQuantity = item.quantity;
            newArrayOfQuantity.push(totalQuantity)
        });

        const totalItems = newArrayOfQuantity.reduce(function(soma, i) {
            return soma + i;
        });

        const totalValue = newArrayOfValue.reduce(function(soma, i) {
            return soma + i;
        });

        updateItems({
            totalItems: totalItems,
            products: cartFiltered,
            totalValue: totalValue
        })

        navigate('/carrinho')
    }

    return (
        <Grid container direction='column' marginBottom='23px'>
            <Grid container direction='row' marginBottom='23px'>
                <Grid xs={12} sm={6} container direction='row'>
                    <img src={image !== null ? image : EmptyImage} style={{ width: 168, height: 132, borderRadius: 10, objectFit: 'cover' }} />
                    <Grid marginLeft='24px' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', maxWidth: '65%' }}>
                        <Text size={24} weight='600' color={Theme.dark.primaryText} style={{ marginBottom: 10 }}>{productName}</Text>
                        <Text size={14} weight='600' color='#C6C8CA'>Detalhes</Text>
                        <Text size={14} weight='600' color={Theme.dark.grey} style={{ marginBottom: 17 }}>Tamanho: {size}</Text>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={6} container direction='row' justifyContent='space-around' style={{ marginTop: 40 }}>
                    <Grid style={{ marginTop: -1 }}>
                        <Text size={14} weight='600' color={Theme.dark.grey} style={{ marginBottom: 8 }}>Quantidade</Text>
                        <div style={{ width: 168, height: '52px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10}}>
                            <Button disabled={newQuantity === 1} onClick={() => handleAddMore('SUB')}>
                                <Text size={24} color={Theme.dark.primary} weight='400'>-</Text>
                            </Button>
                            <InputBase
                                sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
                                value={newQuantity}
                                onChange={(event) => setNewQuantity(event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event)}
                                style={{ fontSize: 24, fontWeight: '600', color: 'white', alignItems: 'center', justifyContent: 'center' }}
                            />
                            <Button disabled={newQuantity === stock} onClick={() => handleAddMore('ADD')}>
                                <Text size={24} color={Theme.dark.primary} weight='400'>+</Text>
                            </Button>
                        </div>


                        <Button onClick={() => handleRemoveProduct(id,size)}>
                            <Text size={12} weight='600' color={Theme.red}>Remover</Text>
                        </Button>
                    </Grid>
                    <Grid>
                        <Text size={14} weight='600' color={Theme.dark.grey}>Preço</Text>
                        <Text size={24} weight='600' color={Theme.dark.primaryText} style={{ marginTop: 16 }}>R${formatNumber(price * newQuantity)}</Text>
                    </Grid>
                </Grid>
            </Grid>
            <Divider flexItem style={{ backgroundColor: Theme.dark.border }} />
        </Grid>
    )
}

export default CartProductCard;
