import styled from 'styled-components';

export const ImgContainer = styled.div.attrs((props) => props)`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`