import {Grid,Skeleton} from "@mui/material";
import Theme from "../../../../style/Theme";
import Text from "../../../../components/Text";
import AlertTriangule from "../../../../assets/images/alert-triangle.png";
import ProductCardPurchasing from "../../../../components/ProductCardPurchasing";
import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router";

const Three = (props) => {

    const { purchase, codChange, disableButton } = props;
    const navigate = useNavigate();

    return (
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" >
            <Grid container item xs={12} md={7}>
                <Grid container padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Grid container height='70px'>
                        <Grid xs={10} md={10.5} height='70px' paddingTop={2.6}>
                            <Text size={24} weight={500}>Aqui está seu código</Text>
                        </Grid>
                    </Grid>
                    <Grid container height='90px'>
                        <Text size={48} weight={600} color={Theme.dark.primary}>{codChange > 0 ? codChange: <Skeleton style={{marginTop: '-10px'}} width={250} height={70} sx={{ bgcolor: 'grey.700' }}/>}</Text>
                    </Grid>
                    <Grid container>
                        <img src={AlertTriangule}/>
                        <Text style={{margin: '2px 0 0 10px'}} size={16} weight={500}>Não esqueça de escrever o código no pacote!</Text>
                    </Grid>
                </Grid>
            </Grid>
            <Grid  item xs={12} md={5} paddingLeft="24px" >
                <Grid  padding='16px' bgcolor={Theme.dark.secondary} borderRadius='8px' border={`1px solid ${Theme.dark.border}`}>
                    <Grid style={{borderBottom: `1px solid ${Theme.dark.border}`,height: 22}}>
                        <Text style={{marginLeft: '7px'}}>Detalhes da compra</Text>
                    </Grid>
                    <Grid container>
                        <Grid xs={4} md={3.4}>
                            <ProductCardPurchasing image={purchase.foto} />
                        </Grid>
                        <Grid xs={8} md={8.6}>
                            <Grid style={{marginTop: 14}}>
                                <Text size={16} color={Theme.dark.green}>Entregue</Text>
                            </Grid>
                            <Grid style={{marginTop: 14}}>
                                <Text size={20} color={Theme.dark.primaryText}>{purchase.desc_prod}</Text>
                                <Text style={{marginTop: 5}} weight={300} size={16} color={Theme.dark.primaryText}>{purchase.quantity} unid | Tam: {purchase.size_selected} </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Button disabled={ disableButton } variant="contained" style={{ width: 296, height: 50, margin: '116px 0 116px 0' }} onClick={() => navigate('/compras')}>
                <Text size={16} weight='500'>Voltar para minhas compras {disableButton}</Text>
            </Button>
        </Grid>
    );
};

export default Three;
