import React, { useState } from 'react';
import Text from '../../../components/Text';
import { Container, Form, HeaderArea } from './styles';
import Theme from '../../../style/Theme';
import InputBase from '@mui/material/InputBase';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';

const FirstAccess = () => {
    const navigate = useNavigate();
    const [und, setUnd] = useState('');
    const [loading, setLoading] = useState(false);

    const unidades = ['Instituto João Bittar', 'Uniessa', 'Bittar Tecnologia'];

    const handleChangeUnd = (event) => {
        setUnd(event.target.value);
    };
    return(
        <Container>
            <HeaderArea>
                <Text size={48} color={Theme.dark.primary} style={{ fontWeight: '600', marginBottom: 12 }}>Olá, Lucas!</Text>
                <Text size={18} color={Theme.dark.grey}>Ajude-nos a personalizar a sua experiência complemantando seu cadastro no primeiro acesso!</Text>
            </HeaderArea>
            <Form>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40 }}>
                        <Text size={18} align='left'>Eu sou da unidade</Text>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
							{/* <Button style={{ padding: 2, marginRight: '-2px', width: '40px', height: '41px' }}>
                                <ExpandMore style={{ color: Theme.dark.primary }} />
							</Button> */}
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <Text size={18}>do endereço</Text>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
							{/* <Button style={{ padding: 2, marginRight: '-2px', width: '40px', height: '41px' }}>
                                <ExpandMore style={{ color: Theme.dark.primary }} />
							</Button> */}
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40  }}>
                        <Text size={18}>Telefone</Text>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="(00) 00000-0000"
								inputProps={{ 'aria-label': '(00) 00000-0000' }}
							/>
							{/* <Button style={{ padding: 2, marginRight: '-2px', width: '40px', height: '41px' }}>
                                <ExpandMore style={{ color: Theme.dark.primary }} />
							</Button> */}
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <Text size={18}>Cargo/Função</Text>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
							{/* <Button style={{ padding: 2, marginRight: '-2px', width: '40px', height: '41px' }}>
                                <ExpandMore style={{ color: Theme.dark.primary }} />
							</Button> */}
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 27  }}>
                        <Text size={18}>Tórax</Text>
                        <div style={{ width: 296, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 108"
								inputProps={{ 'aria-label': 'Medida em cm ex: 108' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 27, marginRight: 27 }}>
                        <Text size={18}>Cintura</Text>
                        <div style={{ width: 296, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 94"
								inputProps={{ 'aria-label': 'Medida em cm ex: 94' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 27 }}>
                        <Text size={18}>Quadril</Text>
                        <div style={{ width: 296, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 112"
								inputProps={{ 'aria-label': 'Medida em cm ex: 112' }}
							/>
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '52.2%', backgroundColor: Theme.dark.border, height: 1, marginTop: 30, marginBottom: 30 }} />
                <Text align='center' size={18}>Para maior segurança altere sua senha</Text>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40 }}>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Nova senha"
								inputProps={{ 'aria-label': 'Nova senha' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <div style={{ width: 460, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Confirmar nova senha"
								inputProps={{ 'aria-label': 'Confirmar nova senha' }}
							/>
						</div>
                    </div>
                </div>
                <Button onClick={() => navigate('/inicio')} style={{ paddingLeft: 80, paddingRight: 80, paddingBottom: 13, paddingTop: 13 }} variant="contained" disableElevation size="large">
                    {
                        loading ?
                        <CircularProgress style={{ color: 'white', width: 20, height: 20 }} />
                        :
                        <Text size={16} style={{ fontWeight: '600' }}>Confirmar primeiro acesso</Text>
                    }
                </Button>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginTop: 150 }}>
                    <Button style={{ borderRadius: 50, borderWidth: 2, borderColor: Theme.dark.primary, marginRight: 350 }} variant="outlined" disableElevation size="medium">
                        <Text size={12} style={{ fontWeight: '600', color: Theme.dark.primary }}>precisa de ajuda?</Text>
                    </Button>
                </div>
                <Text size={12} color={Theme.dark.grey}>Bittar Tecnologia - Todos os direitos reservados</Text>
            </Form>
        </Container>
    )
}

export default FirstAccess;