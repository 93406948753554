import React, { useRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Checkbox, CircularProgress, Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ValidateCPF } from '../../../services/Validation';
import { login } from '../../../api/Auth';
import Button from '../../../components/Button';
import ErrorText from '../../../components/ErrorText';
import Input from '../../../components/Form/Input';
import Text from '../../../components/Text';
import LoadingModal from '../../../components/LoadingModal';

import LogoImage from '../../../assets/images/logo.png';

import Theme from '../../../style/Theme';
import { Container, Form } from './styles';
import { me } from '../../../api/User';

const LoginSchemaValidation = Yup.object().shape({
	cpf: Yup.string()
		.required('Campo obrigatório')
		.test('CPFvalidation', 'CPF inválido', (value) => ValidateCPF(value)),
	password: Yup.string()
		.min(6, 'Senha muito curta')
		.required('Campo obrigatório'),
});

const initialValues = {
	cpf: '',
	password: '',
};

const LoginScreen = () => {
	const passwordInputRef = useRef(null);
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const userStoreActions = useStoreActions(({ user }) => user.setUser);

	const checkAlreadyLogged = async () => {
		setLoading(true)
		const user = JSON.parse(localStorage.getItem('USER'));
		let token = localStorage.getItem('access_token')
		if(user && user.id) {
			const response = await me(user.id);
			setLoading(false)
			if(response.status === 200) {
				await userStoreActions(response.data);
				localStorage.setItem('USER', JSON.stringify(response.data));
				setLoading(false)
				navigate('/inicio')
			} else {
				localStorage.clear();
				setLoading(false)
				navigate('/login')
			}
		} else {
			setLoading(false)
			localStorage.clear();
			navigate('/login')
		}
		setLoading(false)
	};

	useEffect(() => {
		checkAlreadyLogged();
	}, [0]);

	const handleSumbit = async ({ cpf = '', password = '' }) => {
		setLoading(true);

		if(!cpf || !password) {
			setTimeout(() => {
				setLoading(false)
			}, 500)
			return toast('Preencha os campos', { type: 'error' });
		}

		let cleanedCPF = cpf.replace(/\D+/g, '') || '';
		let cleanedPassword = password.replace(/\.|\-/g, '');

		const cpfIsValid = ValidateCPF(cleanedCPF);

		if (!cpfIsValid) {
			setTimeout(() => {
				setLoading(false)
			}, 2000)
			return toast('CPF inválido', { type: 'error' });
		} else if (!cleanedPassword || cleanedPassword.length < 6) {
			return toast('Senha inválida', { type: 'error' });
		}

		try {


			const response = await login({ cpf: cleanedCPF, password: cleanedPassword });
		
			if(response.status === 200) {
				await localStorage.setItem('USER', JSON.stringify(response.data.user));
				await localStorage.setItem('access_token', response.data.token);

				await userStoreActions(response.data.user);
				if(response.data.user.reset_password){
					navigate('/reset-password')
				}else{
					navigate('/inicio')
				}
			}else{
				toast(response.data, { type: 'error' });
			}
		} catch (error) {
			let description = error || 'Ocorreu um erro inesperado.';
		} finally {
			setLoading(false);
		}
		setLoading(false)
	};

	const handleCPF = (value = '', setFieldValue) => {
		setFieldValue('cpf', value);
		let cpfCleaned = typeof (value) === 'string' ? value.replace(/\D+/g, '') : '';
		if (cpfCleaned.length === 11) passwordInputRef.current.focus();
	};

	return (
		<Container>
			{
				loading && <LoadingModal open={loading} setOpen={setLoading} />
			}
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', maxHeight: '100vh', width: '50%' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
					<Grid item style={{ width: '100%', maxWidth: 480, padding: 20 }}>
						<Grid marginTop={-10} container direction="column" alignItems="center" justifyContent="center">
							<img src={LogoImage} style={{ marginBottom: 25 }} />
						</Grid>

						<Formik
							initialValues={initialValues}
							validationSchema={LoginSchemaValidation}
							onSubmit={handleSumbit}
						>
							{({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
								<Form onSubmit={() => handleSumbit({ cpf: values.cpf, password: values.password })}>
									<Text bold color={Theme.dark.secondaryText} size={16}>CPF</Text>
									<InputMask
										mask="999.999.999-99"
										value={values.cpf}
										onChange={(value) => handleCPF(value, setFieldValue)}
										style={{ color: 'white' }}
										onSubmit={() => passwordInputRef.current.focus()}
									>
										{(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 5, backgroundColor: 'transparent', border: '1px solid #4789EB', borderRadius: 8, color: '#fff' }} />}
									</InputMask>
									{errors.cpf && touched.cpf ? (
										<ErrorText message={errors.cpf} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Senha</Text>
									<Input
										type="password"
										forwardedRef={passwordInputRef}
										value={values.password}
										onChange={handleChange('password')}
										onSubmit={handleSubmit}
										isPassword
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.password && touched.password ? (
										<ErrorText message={errors.password} />
									) : null}

									<div style={{ marginTop: 20, marginBottom: 20, flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
											<Checkbox color='text' sx={{ color: Theme.dark.primaryText }}/>
											<Text style={{ color: Theme.dark.primaryText, fontSize: 15, textAlign: 'left' }}>Manter-me conectado</Text>
										</div>
									</div>

									<Button
										background={Theme.dark.primary}
										style={{ marginBottom: -20, padding: 15, width: '100%', height: 55 }}
										height={60}
										disabled={loading}
										onClick={() => handleSumbit({ cpf: values.cpf, password: values.password })}
										type="submit"
									>
										{loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>ACESSAR</Text>}
									</Button>
								</Form>
							)}
						</Formik>
					</Grid>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center', position: 'absolute', bottom: '15%' }}>
                        <Text onClick={() => console.log('oi')} style={{ cursor: 'pointer' }} color={Theme.dark.grey}>Política de privacidade</Text>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: Theme.dark.grey, height: 20, marginLeft: 10, marginRight: 10 }} />
                        <Text onClick={() => console.log('oi')} style={{ cursor: 'pointer' }} color={Theme.dark.grey}>Termos de uso</Text>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: Theme.dark.grey, height: 20, marginLeft: 10, marginRight: 10 }} />
                        <Text onClick={() => console.log('oi')} style={{ cursor: 'pointer' }} color={Theme.dark.grey}>Preciso de ajuda</Text>
                    </div>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: 'transparent', maxHeight: '100vh', width: '50%' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
					</Text>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
					</Text>
				</Grid>
			</Grid>
		</Container>
	)
};

export default LoginScreen;
