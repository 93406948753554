import React, {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {CircularProgress, Grid} from '@mui/material';
import {Formik} from 'formik';
import {useStoreActions} from 'easy-peasy';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import Button from '../../../components/Button';
import ErrorText from '../../../components/ErrorText';
import Input from '../../../components/Form/Input';
import Text from '../../../components/Text';
import LoadingModal from '../../../components/LoadingModal';

import LogoImage from '../../../assets/images/logo.png';

import Theme from '../../../style/Theme';
import {Container, Form} from './styles';
import {resetPass} from "../../../api/ResetPass";


const initialValues = {
	passwordNew: '',
	passwordConfirm: '',
};

const ResetPassword = () => {
	const passwordInputRef1 = useRef(null);
	const passwordInputRef2 = useRef(null);

	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);


	const checkAlreadyLogged = async () => {
		setLoading(true)
		const user = JSON.parse(localStorage.getItem('USER'));
		if(!user || !user.reset_password) {
			setLoading(false)
			localStorage.clear();
			navigate('/login')
		}
		setLoading(false)
	};

	useEffect(() => {
		checkAlreadyLogged();
	}, [0]);

	const handleSumbit = async ({ passwordNew = '', passwordConfirm = '' }) => {
		setLoading(true);
		const usera = JSON.parse(localStorage.getItem('USER'));

		if(passwordConfirm !== passwordNew){
			setTimeout(() => {
				setLoading(false)
			}, 2000)
			return toast('Senhas precisam ser idênticas.', { type: 'error' });
		}

		if(passwordNew.length < 8 || passwordConfirm.length < 8 ){
			setTimeout(() => {
				setLoading(false)
			}, 2000)
			return toast('Mínimo de 8 caracteres.', { type: 'error' });
		}

		try {
			const response = await resetPass({ id: usera.id ,password: passwordNew });

			if(response.status === 200) {
				navigate('/inicio')
			}
		} catch (error) {
			let description = error || 'Ocorreu um erro inesperado.';
			toast(description, { type: 'error' });
		} finally {
			setLoading(false);
		}


		setTimeout(() => {
			setLoading(false)
		}, 1000)

	};

	return (
		<Container>
			{
				loading && <LoadingModal open={loading} setOpen={setLoading} />
			}
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', maxHeight: '100vh', width: '50%' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
					<Grid item style={{ width: '100%', maxWidth: 480, padding: 20 }}>
						<Grid marginTop={-10} container direction="column" alignItems="center" justifyContent="center">
							<img src={LogoImage} style={{ marginBottom: 100 }} />
						</Grid>
						<Grid container direction="column" alignItems="left" justifyContent="left">
							<Text size={40} weight={500}>Crie sua nova senha</Text>
							<Text size={16} color={'#93979F'} weight={500}>Sua nova senha deve ser diferente da senha usada anteriormente.</Text>
						</Grid>
						<Formik
							initialValues={initialValues}
							onSubmit={handleSumbit}
						>
							{({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
								<Form onSubmit={() => handleSumbit({ passwordNew: values.passwordNew, passwordConfirm: values.passwordConfirm })}>

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 35 }}>Nova senha</Text>
									<Input
										type="password"
										forwardedRef={passwordInputRef1}
										value={values.passwordNew}
										onChange={handleChange('passwordNew')}
										onSubmit={handleSubmit}
										isPassword
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.passwordNew && touched.passwordNew ? (
										<ErrorText message={errors.passwordNew} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Confirmar senha</Text>
									<Input
										type="password"
										forwardedRef={passwordInputRef2}
										value={values.passwordConfirm}
										onChange={handleChange('passwordConfirm')}
										onSubmit={handleSubmit}
										isPassword
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.passwordConfirm && touched.passwordConfirm ? (
										<ErrorText message={errors.passwordConfirm} />
									) : null}

									<Button

										background={Theme.dark.primary}
										style={{ marginTop: 20,marginBottom: -20, padding: 15, width: '100%', height: 55 }}
										height={60}
										disabled={loading}
										onClick={() => handleSumbit({ passwordNew: values.passwordNew, passwordConfirm: values.passwordConfirm })}
										type="submit"
									>
										{loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>VERIFICAR</Text>}
									</Button>
								</Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: 'transparent', maxHeight: '100vh', width: '50%' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
					</Text>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
					</Text>
				</Grid>
			</Grid>
		</Container>
	)
};

export default ResetPassword;
