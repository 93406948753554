import styled from 'styled-components';
import BackgroundImage from '../../../assets/images/bg-circles.png';

export const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 899px) {
    display: none;
  }
`;

export const Form = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -8%;
`;

export const HeaderArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  max-height: 100px;
  margin-top: -1%;
`