import Styled from 'styled-components';
import { Button as MuiButton } from '@mui/material';

export const Button = Styled(MuiButton)`
  background-color: ${({ background, theme }) => background ? background : theme.white.primary} !important;
  border-radius: ${({ rounded }) => rounded ? '54px' : '8px'};
  height: ${({ height }) => height || 'auto'};
  padding:  ${({ padding }) => padding || '15px 25'}px;
  width: ${({ width }) => width || 'auto'};
`;
