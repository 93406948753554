import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import ProductCard from '../../../components/ProductCard';
import Text from '../../../components/Text';
import BgLogin from '../../../assets/images/background-login.png';
import { ImgContainer } from './styles';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import BannerHome from '../../../assets/images/banner-home.png';
import { getHome } from '../../../api/Home'
import { useStoreState } from 'easy-peasy';

const Home = () => {
    const navigate = useNavigate();
    const user = useStoreState(({ user }) => user.user);

    const [ category, setCategory ] = useState(null);
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState('');

    const logout = () => {
        localStorage.clear();
        navigate('/');
        window.location.replace('/');
    };

    const getProducts = async () => {
        try {

            let token = localStorage.getItem('access_token')

            const response = await getHome(token);
            if (response.status === 200) {
                const list1 = response.data.data;
                let list2 = [];
                const divisor = 4;
                for (var i = 0; i < list1.length; i = i + divisor) {
                    list2.push(list1.slice(i, i + divisor));
                }
                setCategory(list2)
            } else if (response.status === 403) {
                logout();
            } else {
                setMessageError('Ops! Ocorreu um problema.')
                setError(true)
            }
        } catch (error) {
            console.log(error)
            setMessageError('Ops! Ocorreu um problema.')
            setError(true)
        }
    };

    useEffect(() => {
        getProducts()
    }, [])
    
    return (
        <div>
            <div>
                <Carousel indicators={false} height={820}>
                    <img src={BannerHome} style={{ height: 1080, width: '100%', objectFit: 'contain' }} />
                </Carousel>
            </div>
            <Container style={{ marginTop: 50 }}>
                <Text size={24} weight='600'>Para o dia a dia</Text>
                <Grid container direction='row' marginTop={3} marginBottom={7}>
                    {
                        category && category[0].map((item) => <ProductCard id={item.cod_prod} key={item.cod_prod} name={item.desc_prod} price={parseInt(item.cost)} image={item.link_img01} addToCart={() => console.log('addtocart')} onClick={() => navigate(`/produto/${item.cod_prod}`, { state: {product: item} })} />)
                    }
                </Grid>
                <Text size={24} weight='600'>Destaques</Text>
                <Grid container direction='row' marginTop={3} marginBottom={7}>
                    {
                        category && category[1].map((item) => <ProductCard id={item.cod_prod} key={item.cod_prod} name={item.desc_prod} price={parseInt(item.cost)} image={item.link_img01} addToCart={() => console.log('addtocart')} onClick={() => navigate(`/produto/${item.cod_prod}`, { state: {product: item} })} />)
                    }
                </Grid>
            </Container>
            <ImgContainer image={BgLogin}>
                <Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
                    Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
                </Text>
                <Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
                    Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
                </Text>
            </ImgContainer>
        </div>
    )
}

export default Home;
