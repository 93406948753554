export const ValidateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const ValidateCPF = (strCPF = '') => {
	let sum;
	let rest;
  sum = 0;
	strCPF = strCPF.replace(/\D+/g, '');

	if (strCPF == '00000000000') return false;

	for (let i=1; i<=9; i++) {
		sum = sum + parseInt(strCPF.substring(i-1, i)) * (11 - i);
	}

	rest = (sum * 10) % 11;

	if ((rest == 10) || (rest == 11)) rest = 0;
	if (rest != parseInt(strCPF.substring(9, 10))) return false;

	sum = 0;
	for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i-1, i)) * (12 - i);
	rest = (sum * 10) % 11;

	if ((rest == 10) || (rest == 11))  rest = 0;
	if (rest != parseInt(strCPF.substring(10, 11) ) ) return false;
	return true;
};

export default {
  ValidateEmail,
  ValidateCPF
};
