import { Grid, Toolbar, Divider, Button } from '@mui/material';
import React from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import { useNavigate } from 'react-router';
import Menu from '../../../components/Menu';
import ListUserContainer from '../../../components/ListUserContainer';

const Users = () => {
    const navigate = useNavigate();
    const users = [
        { id: 1, username: 'Lucas Bras', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 2, username: 'Devon Lane', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 3, username: 'Savannah Nguyen', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 4, username: 'Annette Black', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 5, username: 'Guy Hawkins', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 6, username: 'Bessie Cooper', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 7, username: 'Jenny Wilson', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 8, username: 'Eleanor Pena', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 9, username: 'Arlene McCoy', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 10, username: 'Jacob Jones', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' },
        { id: 11, username: 'Courtney Henry', unidade: 'Bittar Educação', ocupation: 'Designer UI/UX', lastAccess: '22/07/2022', status: 'Ativo' }
    ];

    return (
        <div>
            <Menu />
            <Toolbar>
                <Grid container direction='row' style={{ paddingLeft: '17%' }}>
                    <PeopleAltOutlinedIcon sx={{ color: Theme.dark.primary, paddingRight: 1 }} />
                    <Text size={24} weight='600'>Usuários</Text>
                </Grid>
                <Button sx={{ width: '133px', height: '31px', padding: '8px' }} variant='contained'>
                    <Text size={12} weight='700'>NOVO USUÁRIO</Text>
                </Button>
            </Toolbar>
            <Divider style={{ backgroundColor: Theme.dark.border }} />
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ paddingLeft: '18%', paddingTop: 5, width: '100%' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ width: '70%' }}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Text size={18} weight='600'>Lista de usuários</Text>
                        <Button sx={{ marginRight: '27%' }}>
                            <Text size={12} weight='500'>Último acesso</Text>
                        </Button>
                    </Grid>
                    <ListUserContainer users={users} />
                </Grid>
                <Grid container direction='column' style={{ width: '30%' }}>                    
                    <Grid style={{ marginLeft: '10%', width: '90%' }}>
                        <Text size={18} weight='600'>Notificações</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
                        <Text size={10} weight='500'>O item <spam style={{ fontWeight: 'bold', color: Theme.dark.primary }}>Camiseta azul</spam>, está com o estoque baixo!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>3 Peças</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.danger}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#03215 Lucas Brás</spam>, está atrasado do prazo de entrega!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                        <Text size={18} weight='500'>2 Dias</Text>
                    </Grid>
                    <Grid container sx={{ backgroundColor: Theme.dark.secondary, marginLeft: '10%', padding: '7px', width: '310px', minHeight: '42px', maxHeight: '70px', marginRight: '16px', marginTop: '12px', borderRadius: '8px', border: `1px solid ${Theme.dark.yellow}` }}>
                        <Text size={10} weight='500'>O pedido <spam style={{ fontWeight: 'bold' }}>#1562 Arlene McCoy</spam>, precisa ser enviado para os correios!</Text>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '2px', marginTop: '4px', width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Users;