import styled from 'styled-components';
import {Grid} from "@mui/material";
import BackgroundImage from "../../../assets/images/background-login.png";
import Theme from "../../../style/Theme";

import ArrowDown from '../../../assets/images/arrow-down.png'

export const ImgContainer = styled.div.attrs((props) => props)`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const HeaderImgContainer = styled.div.attrs((props) => props)`
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const SelectOptions = styled.select`
  background: url(${ArrowDown}) no-repeat right ${Theme.dark.background};
  width: 150px;
  height: 53px;
  font-size: 18px;
  color: white;
  border: 0 !important;
  text-indent: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px;
  option {
    background: ${Theme.dark.background};
    color: white;
  }
  :focus {
    outline: none;   
  }

  
`;


