import React from 'react';
import { Divider, Grid } from '@mui/material';
import Text from '../Text';
import Theme from '../../style/Theme';

const ListUserPedidosContainer = (props) => {
    const { sales } = props;

    return(
        <Grid container direction='row' sx={{ width: '100%', marginTop: '8px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '21px', backgroundColor: Theme.dark.secondary, borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
            {
                sales && sales.map((item) => (
                    <Grid container sx={{ width: '100%' }} key={item.id}>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%', marginBottom: '10px' }}>
                            <Text size={12} weight='600' style={{ width: '10%' }}>#{item.numberSale}</Text>
                            <Text size={12} weight='400' style={{ width: '30%' }}>{item.name}</Text>
                            <Text size={12} weight='500' style={{ width: '20%' }}>{item.date}</Text>
                            <Text size={12} weight='500' style={{ width: '20%' }}>{item.quantity} itens</Text>
                            <Text size={12} weight='500' style={{ width: '10%' }} color={Theme.dark.primary}>R${item.totalPrice}</Text>
                        </Grid>
                        <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '10px', width: '100%' }} />
                    </Grid>
                ))
            }
        </Grid>
    )
};

export default ListUserPedidosContainer;