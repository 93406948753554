import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Drawer,
	Toolbar,
	Grid,
	Menu,
	MenuItem,
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
    Tooltip,
    TooltipProps, 
    tooltipClasses,
		Badge
} from '@mui/material';
import { toast } from 'react-toastify';
import {useStoreActions, useStoreState} from 'easy-peasy';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InputBase from '@mui/material/InputBase';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import moment from 'moment';
import { styled } from '@mui/material/styles';

import { AccountCircle, ExpandMore, Search, Menu as MenuIcon } from '@mui/icons-material';
import LogoWhite from '../../assets/images/logo.png';
import Theme from '../../style/Theme';

import Text from '../Text';
import DrawerContent from './components/DrawerContent';
import { formatNumber } from '../../utils/formatMoney';
import {putCredits} from "../../api/Checkout";
import LoadingModal from "../LoadingModal";
import {me} from "../../api/User";

let PATHS_TO_SHOW_TOOLBAR = [
	'/inicio',
	'/resultados',
	'/produto/',
	'/produtos',
	'/carrinho',
	'/finalizar-compra',
	'/perfil',
	'/dress-policy',
	'/compras',
	'/troca',
];

const PATHS_TO_HIDE_DRAWER = [
	'/aula',
	'/aprendizado'
];

const MainDrawer = ({ children }) => {
	const [open, setOpen] = React.useState(false);

	const user = useStoreState(({ user }) => user.user);
	const userStoreActions = useStoreActions(({ user }) => user.setUser);
	const [loading, setLoading] = React.useState(false);

	const totalItemsCart = useStoreState(({ cart }) => cart.totalItems);

	const firstName = user && user?.name && user.name.split(' ')[0];
	const nameFormatted = firstName && firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()
	const location = useLocation();
	const navigate = useNavigate();

	const [dialogLogout, setDialogLogout] = React.useState(false);
	const [dialogEditCredit, setDialogEditCredit] = React.useState(false);

	const [newCredit, setNewCredit] = React.useState('');

	const handleOpenDialogLogout = () => setDialogLogout(true);
	const handleCloseDialogLogout = () => setDialogLogout(false);

	const handleOpenDialogEditCredit = async () => {
		setNewCredit(parseInt(user.credits));
		setDialogEditCredit(true);
	}
	const handleCloseDialogEditCredit = () => setDialogEditCredit(false);


	const handleDrawerOpen = () => setOpen(true)

	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleMenu = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const handleLogout = () => {
		handleOpenDialogLogout();
	};

	const handleEditCredit = () => {
		handleOpenDialogEditCredit();
	}

	const logout = () => {
		handleClose();
		handleCloseDialogLogout();

		localStorage.clear();
		navigate('/');
		window.location.replace('/');
	};
	const handleUpdateCredit = async () => {
		try {
			setLoading(true);
			let valueNewCredit = parseInt(newCredit);

			if (valueNewCredit <= 0)
				return toast('O crédito deve ser maior do que zero.', { type: 'error' });
			if (valueNewCredit > 999)
				return toast('O crédito deve ser menor do que 1000,00.', { type: 'error' });

			await putCredits({
				id_user: user.id,
				credits: valueNewCredit
			});

			user.credits= newCredit;
			userStoreActions(user);
			setNewCredit('');
			localStorage.setItem('USER', JSON.stringify(user));

		} catch (error) {
			toast(error, { type: 'error' })
		} finally {
			handleCloseDialogEditCredit();
			setLoading(false);
		}
	}

	const handlerGetUser = async () =>{

		try{
			let localUser = await JSON.parse(localStorage.getItem('USER'));
			const response = await me(localUser.id);

			if(response.status === 200) {
				userStoreActions(response.data);
			} else if (response.status === 403){
				logout();
			}

		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() =>{
		handlerGetUser()
	},[]);

	const isToShowDrawer = !PATHS_TO_HIDE_DRAWER.includes(location.pathname.replace(/[0-9]/g, ''));
	const isToShowToolbar = PATHS_TO_SHOW_TOOLBAR.includes(location.pathname.replace(/[0-9]/g, ''));
	const BootstrapTooltip = styled(({ className, ...props }) => (
			<Tooltip {...props} arrow classes={{ popper: className }} />
		))(({ theme }) => ({
			[`& .${tooltipClasses.arrow}`]: {
				color: Theme.dark.border,
			},
			[`& .${tooltipClasses.tooltip}`]: {
				backgroundColor: Theme.dark.border,
                height: '42px',
                width: '261px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                fontSize: '12px',
                fontWeight: 'bold'
			},
		})
    );
		const [search, setSearch] = React.useState('');
		const findProducts = () => {
			navigate(`/produtos?busca=${search}`,{replace: true})
		}
		const handleKeyDown = (event) => {
			if (event.key === 'Enter' && search !== '') {
				navigate(`/produtos?busca=${search}`,{replace: true})
			}
		}

	if (!isToShowToolbar) return children;

	return (
		<div style={{ flex: 1 }}>
			{
				loading && <LoadingModal open={loading} setOpen={setLoading} />
			}
			<Dialog
				open={dialogLogout}
				keepMounted
				onClose={handleCloseDialogLogout}
				aria-describedby="logout-dialog"
			>
				<DialogTitle style={{ color: Theme.dark.primaryText, backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>Sair</DialogTitle>
				<DialogContent style={{ backgroundColor: Theme.dark.secondary, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
						Você realmente deseja sair?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<Button onClick={handleCloseDialogLogout}>Cancelar</Button>
					<Button onClick={logout}>Sair</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={dialogEditCredit}
				keepMounted
				onClose={handleCloseDialogEditCredit}
				aria-describedby="edit-credit-dialog"
			>
				<DialogTitle style={{ color: Theme.dark.primaryText, backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>Editar Crédito</DialogTitle>
				<DialogContent style={{ backgroundColor: Theme.dark.secondary, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
						<div style={{ width: 200, height: '38px', display: 'flex', alignItems: 'center', border: `2px solid ${Theme.dark.detail}`, borderRadius: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="0,00"
								inputProps={{ 'aria-label': '0,00' }}
								type="number"
								value={newCredit}
								onChange={(event) => setNewCredit(event.target.value)}
							/>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<Button onClick={handleCloseDialogEditCredit} >Cancelar</Button>
					<Button onClick={handleUpdateCredit}>Salvar</Button>
				</DialogActions>
			</Dialog>

			<Toolbar style={{ backgroundColor: Theme.dark.tertiary }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<div style={{ display: 'flex', flexDirection: 'row', width: '35%' }}>
						<img src={LogoWhite} onClick={() => navigate('/inicio')} style={{ cursor: 'pointer', height: 35, marginLeft: '15%' }} />
						{/* <Button size='large' style={{ width: '40%' }}>
							<Text>Política de troca</Text>
						</Button>
						<Button
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							style={{ marginRight: '5%', color: Theme.dark.primaryText }}
						>
							<Text color={Theme.dark.primaryText} size={14} style={{ marginLeft: 7 }}>
								Dicas
							</Text>
							<ExpandMore style={{ color: Theme.dark.primaryText }} />
						</Button> */}
						<Button onClick={() => navigate('/produtos')} size='large' style={{ width: 80 }}>
							<Text>Loja</Text>
						</Button>
					</div>
					<div>
						<div style={{ width: 400, height: '38px', display: 'flex', alignItems: 'center', border: `2px solid ${Theme.dark.detail}`, borderRadius: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Procurar por camisas, calças, roupas sociais..."
								inputProps={{ 'aria-label': 'Procurar por camisas, calças, roupas sociais...' }}
								value={search}
								onChange={(event) => setSearch(event.target.value)}
								onKeyDown={handleKeyDown}
							/>
							<Button onClick={findProducts} style={{ padding: 2, backgroundColor: Theme.dark.primary, marginRight: '-2px', width: '40px', height: '41px', borderRadius: '8px', border: `2px solid ${Theme.dark.primary}` }}>
								<Search style={{ color: 'white', width: 20, height: 20 }} />
							</Button>
						</div>
					</div>
					<div style={{ width: '20%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                        <MonetizationOnOutlinedIcon style={{ color: Theme.dark.primaryText, width: 28, height: 28 }} />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                        <BootstrapTooltip title={<Text style={{ display: 'flex', flexDirection: 'row' }} size={12} bold>Seus créditos expiram: <Text style={{ marginLeft: 5 }} color={Theme.dark.success} size={12} bold>{moment(user.expirationDate).format('DD/MM/YYYY')}</Text></Text>}>
                                                <div>
                                                        <Text style={{ fontSize: 10, lineHeight: 1 }}>Seus créditos</Text>
                                                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{formatNumber(user.credits)}</Text>
                                                </div>
                                        </BootstrapTooltip>
                                </div>
                        </div>
                        <Badge badgeContent={totalItemsCart} style={{ color: 'white', marginLeft: '5%', marginRight: '10%', cursor: 'pointer' }} onClick={() => navigate('/carrinho')} color="red">
                            <ShoppingCartOutlinedIcon style={{ color: Theme.dark.primaryText }} />
                        </Badge>
                        <Button
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            style={{ marginRight: '5%', color: Theme.dark.primaryText }}
                        >
                            <Text color={Theme.dark.primaryText} size={14} style={{ marginLeft: 7, marginRight: 2 }}>
                                Olá, {nameFormatted}
                            </Text>
                            <ExpandMore style={{ color: Theme.dark.primaryText }} />
                        </Button>
					</div>

					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={!!anchorEl}
						onClose={handleClose}
					>
						{/* <MenuItem onClick={() => { handleClose(); navigate('/perfil'); }}>Editar perfil</MenuItem> */}

						{ user.order &&
							<MenuItem divider={true} onClick={() => { handleClose(); handleEditCredit(); }}> Editar Crédito	</MenuItem>
						}
						<MenuItem divider={true} onClick={() => { handleClose(); navigate('/compras'); }}> Compras </MenuItem>
						<MenuItem onClick={() => { handleClose(); handleLogout(); }}> Sair </MenuItem>
					</Menu>
				</Grid>
			</Toolbar>

			{isToShowDrawer && (
				<Drawer
					variant="temporary"
					anchor="left"
					PaperProps={{ backgroundColor: Theme.dark.secondary }}
					open={open}
					onBackdropClick={() => setOpen(false)}
				>
					<DrawerContent
						path={location.pathname}
						onClose={() => setOpen(false)}
						onLogout={handleOpenDialogLogout}
					/>
				</Drawer>
			)}
			{children}
		</div>
	);

};
export default MainDrawer;
