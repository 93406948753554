import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../components/Text';

import Theme from '../../style/Theme';

const ErrorText = ({ message, style }) => (
  <Text
    color={Theme.dark.poor}
    style={{ marginTop: 10, ...style }}
  >
    {message}
  </Text>
);

ErrorText.defaultProps = {
  message: '',
  style: {},
};

ErrorText.prototype = {
  message: PropTypes.string,
  style: PropTypes.object,
};

export default ErrorText;
