import React from 'react';
import { Divider, Grid } from '@mui/material';
import Text from '../Text';
import Theme from '../../style/Theme';

const ListDataUserContainer = (props) => {
    const { sales } = props;

    return(
        <Grid container direction='row' sx={{ width: '100%', marginTop: '8px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '21px', backgroundColor: Theme.dark.secondary, borderRadius: '8px', border: `1px solid ${Theme.dark.border}` }}>
            <Grid container sx={{ width: '100%' }}>
                <Grid container direction='row' alignItems='center' justifyContent='flex-start' sx={{ width: '100%', marginBottom: '10px' }}>
                    <Text size={12} weight='600'>Unidade:</Text>
                    <Text size={12} weight='400' style={{ marginLeft: 3 }}>Bittar Educação</Text>
                </Grid>
                <Grid container direction='row' alignItems='center' justifyContent='flex-start' sx={{ width: '100%', marginBottom: '10px' }}>
                    <Text size={12} weight='600'>Endereço:</Text>
                    <Text size={12} weight='400' style={{ marginLeft: 3 }}>
                    Rua George Ohm, 230, Conjunto 121 Torre B - Cidade Monções - São Paulo - SP, CEP: 04576-020
                    </Text>
                </Grid>
                <Divider style={{ backgroundColor: Theme.dark.border, marginBottom: '10px', width: '100%' }} />
                <Grid container direction='row' alignItems='center' justifyContent='flex-start' sx={{ width: '100%', marginBottom: '10px' }}>
                    <Text size={12} weight='600'>Tel/Whats:</Text>
                    <Text size={12} weight='400' style={{ marginLeft: 3 }}>(19) 99631-8301</Text>
                </Grid>
                <Grid container direction='row' alignItems='center' justifyContent='flex-start' sx={{ width: '100%', marginBottom: '10px' }}>
                    <Text size={12} weight='600'>E-mail:</Text>
                    <Text size={12} weight='400' style={{ marginLeft: 3 }}>
                    lucas.bras@bittareducacao.com.br
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ListDataUserContainer;