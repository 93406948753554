import React, { useState } from 'react';
import Text from '../../../components/Text';
import { Form, HeaderArea } from './styles';
import Theme from '../../../style/Theme';
import InputBase from '@mui/material/InputBase';
import { Button, CircularProgress, Grid, Breadcrumbs, Link, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router';
import EditIcon from '../../../assets/images/edit-icon.png';
import Camisa from '../../../assets/images/camisa.jpeg';
import Sueter from '../../../assets/images/sueter.jpeg';
import Jaqueta from '../../../assets/images/jaqueta.jpeg';
import Blusa from '../../../assets/images/blusa.jpeg';
import ProductCard from '../../../components/ProductCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Profile = () => {
    const navigate = useNavigate();
    const [und, setUnd] = useState('');
    const [loading, setLoading] = useState(false);

    const unidades = ['Instituto João Bittar', 'Uniessa', 'Bittar Tecnologia'];

    const handleChangeUnd = (event) => {
        setUnd(event.target.value);
    };

    const category1 = [
        { productName: 'Camisa social', price: 'R$120,00', image: Camisa },
        { productName: 'Suéter', price: 'R$89,90', image: Sueter },
        { productName: 'Jaqueta sport', price: 'R$155,00', image: Jaqueta },
        { productName: 'Blusa de lã', price: 'R$78,90', image: Blusa }
    ];

    return(
        <Container>
            <Breadcrumbs style={{ fontWeight: '700', color: Theme.dark.border, marginTop: 100, marginBottom: 20, fontSize: 12 }} aria-label="breadcrumb">
                <Link underline="hover" color={Theme.dark.grey} fontSize={12} fontWeight='700' href="/inicio">
                    Home
                </Link>
                <Typography fontSize={12} fontWeight='700' color={Theme.dark.grey}>Editar perfil <spam style={{ color: Theme.dark.border, marginLeft: 6, fontWeight: '700' }}>/</spam></Typography>
            </Breadcrumbs>
            <Grid>
                <Text size={24} color={Theme.dark.primaryText} style={{ fontWeight: '600', marginBottom: 12 }}>Meus dados</Text>
            </Grid>
            <Form>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40 }}>
                        <Text size={18} align='left'>Eu sou da unidade</Text>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <Text size={18}>do endereço</Text>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40  }}>
                        <Text size={18}>Telefone</Text>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="(00) 00000-0000"
								inputProps={{ 'aria-label': '(00) 00000-0000' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <Text size={18}>Cargo/Função</Text>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Escolha uma opção"
								inputProps={{ 'aria-label': 'Escolha uma opção' }}
							/>
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 27  }}>
                        <Text size={18}>Tórax</Text>
                        <div style={{ width: 343, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 108"
								inputProps={{ 'aria-label': 'Medida em cm ex: 108' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 27, marginRight: 27 }}>
                        <Text size={18}>Cintura</Text>
                        <div style={{ width: 343, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 94"
								inputProps={{ 'aria-label': 'Medida em cm ex: 94' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 27 }}>
                        <Text size={18}>Quadril</Text>
                        <div style={{ width: 343, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Medida em cm ex: 112"
								inputProps={{ 'aria-label': 'Medida em cm ex: 112' }}
							/>
						</div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '99%', backgroundColor: Theme.dark.border, height: 1, marginTop: 30, marginBottom: 30 }} />
                <Grid container>
                    <Text align='left' style={{ marginLeft: 5 }} size={18}>Editar senha</Text>
                    <img src={EditIcon} style={{ width: 24, height: 24, marginLeft: 25 }} />
                </Grid>
                <div style={{ display: 'flex', width: '100%', height: 80, alignItems: 'flex-start', justifyContent: 'center', marginTop: 25, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40 }}>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Nova senha"
								inputProps={{ 'aria-label': 'Nova senha' }}
							/>
						</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                        <div style={{ width: 530, height: '49px', display: 'flex', alignItems: 'center', border: `1px solid ${Theme.dark.border}`, borderRadius: 10, marginTop: 10}}>
							<InputBase
								sx={{ ml: 1, flex: 1, color: Theme.dark.grey }}
								placeholder="Confirmar nova senha"
								inputProps={{ 'aria-label': 'Confirmar nova senha' }}
							/>
						</div>
                    </div>
                </div>
                <Grid container justifyContent='flex-end'>
                    <Button onClick={() => navigate('/inicio')} style={{ paddingLeft: 80, paddingRight: 80, paddingBottom: 13, paddingTop: 13 }} variant="contained" disableElevation size="large">
                        {
                            loading ?
                            <CircularProgress style={{ color: 'white', width: 20, height: 20 }} />
                            :
                            <Text size={16} style={{ fontWeight: '600' }}>Salvar alterações</Text>
                        }
                    </Button>
                </Grid>
            </Form>
            <Text size={24} weight='600'>Destaques</Text>
            <Grid container direction='row' marginTop={3} marginBottom={7}>
                {
                    category1.map((item, index) => <ProductCard key={index} name={item.productName} price={item.price} image={item.image} addToCart={() => console.log('addtocart')} onClick={() => console.log('onclick')} />)
                }
            </Grid>
            <Grid container alignItems='center' justifyContent='center' marginBottom='32px' marginTop='-50px'>
                <Button endIcon={<ArrowForwardIcon />} onClick={() => navigate('/produtos')}>
                    <Text size={14} weight='700' color={Theme.dark.primary}>VER LOJA COMPLETA</Text>
                </Button>
            </Grid>
        </Container>
    )
}

export default Profile;