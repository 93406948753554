import React from 'react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Button = (props) => {
  const { children, loading } = props;

  return (
    <Styled.Button
      {...props}
    >
      {loading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : children}
    </Styled.Button>
  )
};

Button.defaultProps = {
  background: 'inherit',
  loading: false,
  height: 'auto',
  padding: 'initial',
  rounded: false,
  width: null,
}

Button.propTypes = {
  background: PropTypes.string,
  loading: PropTypes.bool,
  height: PropTypes.any,
  padding: PropTypes.any,
  rounded: PropTypes.bool,
  width: PropTypes.any,
};

export default Button;
